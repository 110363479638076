import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

function InstallationCurrentEnergyPriceCard({ installation }) {
    const [currentEnergyPrice, setCurrentEnergyPrice] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!installation) {
            return;
        }
        setError(null);
        setCurrentEnergyPrice(null);
        axios.post("/getInstallationCurrentEnergyPrice", { installationId: installation.id })
            .then((response) => {
                setCurrentEnergyPrice(response.data.currentEnergyPrice);
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            });
    }, [installation]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    Huidige energieprijs
                    <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                        excl. btw, incl. inkoopkosten en energiebelasting
                    </div>
                </h5>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : currentEnergyPrice === null ? (
                    <div style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                        <Skeleton/>
                    </div>
                ) : (
                    <div style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                        &euro;{" "}
                        { currentEnergyPrice.toFixed(5).replace(".", ",") }{" "}
                        <small className="text-muted">/kWh</small>
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(InstallationCurrentEnergyPriceCard);
