import React, {
    useEffect,
    useRef,
    useState
} from "react";
import moment from "moment";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";

import Loading from "../../../../components/Loading";

function EnergyHandlingModeItem({ selectedMode, totalAmount, hideLabels, timeFormat = "HH:mm", rotateLabels }) {
    return (
        <OverlayTrigger overlay={
            <Tooltip id={`tooltip-EnergyHandlingModeItem`}>
                <b>
                    { moment(selectedMode.date).format(timeFormat) }
                </b>
                <br/>
                { selectedMode.mode.name }
            </Tooltip>
        }>
            <div
                className="energy-handling-item-container"
                style={{
                    width: `${100 / totalAmount}%`
                }}
            >
                <div
                    className="energy-handling-item-block"
                    style={{
                        backgroundColor: selectedMode.mode.color
                    }}
                />
                { !hideLabels && (
                    <div
                        className={"energy-handling-item-text" + (rotateLabels ? " rotate" : "")}
                    >
                        {moment(selectedMode.date).format(timeFormat)}
                    </div>
                )}
            </div>
        </OverlayTrigger>
    )
}

function EnergyHandlingChart({ modes, unavailable, hideLabels }) {
    const chartRef = useRef();
    const windowSize = useWindowSize();
    const [rotateLabels, setRotateLabels] = useState(false);

    useEffect(() => {
        if(!chartRef.current) {
            setRotateLabels(false);
            return;
        }
        if(chartRef.current.offsetWidth >= 800) {
            setRotateLabels(false);
            return;
        }
        setRotateLabels(true);
    }, [chartRef, windowSize]);

    if(unavailable) {
        return (
            <p className="m-0">
                Geen sturing beschikbaar.
            </p>
        );
    }
    if(!modes) {
        return (
            <Loading/>
        );
    }
    return (
        <div ref={ chartRef } className="energy-handling-chart-bar">
            { modes.map((selectedMode) => (
                <EnergyHandlingModeItem
                    key={ selectedMode.date }
                    selectedMode={ selectedMode}
                    totalAmount={ modes.length }
                    hideLabels={ hideLabels }
                    timeFormat="HH:mm"
                    rotateLabels={ rotateLabels }
                />
            ))}
        </div>
    );
}

export default React.memo(EnergyHandlingChart);
