import React, {
    useCallback,
    useState
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import NumberFormatter from "../../../../components/formatters/NumberFormatter";
import DateFormatter from "../../../../components/formatters/DateFormatter";
import BatteryHistoryModal from "./BatteryHistoryModal";
import DeviceIcon from "../../../../components/DeviceIcon";

function Row({ title, children }) {
    return (
        <div className="row">
            <div className="col-5 text-right">
                <b>{ title }</b>
            </div>
            <div className="col">
                { children }
            </div>
        </div>
    )
}

export function InstallationBatteryCard({ battery, onClickAddDevice, allowHistory, disabled }) {
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    const handleCloseHistoryModal = useCallback(() => {
        setShowHistoryModal(false);
    }, []);

    return (
        <div className="card mb-3">
            { allowHistory && (
                <BatteryHistoryModal
                    battery={ battery }
                    show={ showHistoryModal }
                    handleClose={ handleCloseHistoryModal }
                />
            )}
            <div className="card-body">
                <div className="d-flex flex-row align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { battery.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { battery.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { battery.serialNumber }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3 d-flex flex-row align-items-center">
                        { allowHistory && (
                            <OverlayTrigger overlay={
                                <Tooltip id={ `battery-history-${battery.id}` }>
                                    Bekijk historie
                                </Tooltip>
                            }>
                                <Button
                                    variant="link"
                                    className="py-0"
                                    onClick={ () => setShowHistoryModal(true) }
                                >
                                    <i className="fa-solid fa-history fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                        )}
                        <DeviceIcon deviceType="battery" soe={ battery.soe }/>
                    </div>
                </div>
                <Row title="Laadtoestand">
                    { battery.soe !== null ? (
                        <React.Fragment>
                            <NumberFormatter number={ battery.soe }/>%
                        </React.Fragment>
                    ) : "Unknown"}
                </Row>
                <Row title="Gezondheid">
                    { battery.soh !== null ? (
                        <React.Fragment>
                            <NumberFormatter number={ battery.soh }/>%
                        </React.Fragment>
                    ) : "Unknown" }
                </Row>
                <Row title="DC Power">
                    { battery.powerDc !== null ? (
                        <React.Fragment>
                            <NumberFormatter number={ battery.powerDc }/> W
                        </React.Fragment>
                    ) : "Unknown" }
                </Row>
                <Row title="Capaciteit">
                    { battery.ratedEnergy !== null ? (
                        <React.Fragment>
                            <NumberFormatter number={ battery.ratedEnergy / 1000 }/> kWh
                        </React.Fragment>
                    ) : "Unknown" }
                </Row>
                <Row title="Laatst gezien">
                    <DateFormatter date={ battery.lastSeenDate }/>
                </Row>
                { onClickAddDevice && battery.installationId === null && (
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => onClickAddDevice("battery", battery.id) }
                            disabled={ disabled }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Batterij toevoegen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

function InstallationBatteryCards({ batteries, onClickAddDevice, disabled }) {
    return batteries.map((battery) => (
        <InstallationBatteryCard
            key={battery.id}
            battery={battery}
            onClickAddDevice={onClickAddDevice}
            disabled={disabled}
        />
    ))
}

export default React.memo(InstallationBatteryCards);
