import React from "react";
import Skeleton from "react-loading-skeleton";

import InfoCardRow from "../../../../../components/InfoCardRow";

function InstallationInfoCard({ installation }) {
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title mb-0">Installatie info</h5>
                <InfoCardRow title="Naam">
                    { installation ? (
                        installation.name
                    ) : (
                        <Skeleton/>
                    )}
                </InfoCardRow>
                <InfoCardRow title="Adres">
                    { installation ? (
                        <React.Fragment>
                            { installation.address }
                            <br/>
                            { installation.postalCode } { installation.city }
                        </React.Fragment>
                    ) : (
                        <Skeleton count={ 2 }/>
                    )}
                </InfoCardRow>
                { installation && installation.company && (
                    <InfoCardRow title="Installateur">
                        { installation.company.name }
                    </InfoCardRow>
                )}
            </div>
        </div>
    )
}

export default React.memo(InstallationInfoCard);
