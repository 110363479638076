import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import DateFormatter from "../../components/formatters/DateFormatter";

function Gateways() {
    const [gateways, setGateways] = useState(null);
    const [error, setError] = useState(null);

    const refreshGateways = useCallback(async () => {
        try {
            const response = await axios.get("/getGateways");
            setGateways(response.data.gateways);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, []);
    useEffect(() => {
        refreshGateways();
    }, [refreshGateways]);

    return (
        <React.Fragment>
            <Helmet title="Gateways"/>
            <Title preTitle="Overzicht" noBottom={ !!gateways }>
                Gateways
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !gateways ? (
                <Loading/>
            ) : (
                <Table hover>
                    <thead>
                    <tr className="tr-sticky">
                        <th>UUID</th>
                        <th>Laatst online</th>
                        <th>Toegewezen installatie</th>
                        <th className="text-center">Apparaten</th>
                    </tr>
                    </thead>
                    <tbody>
                    { gateways.map((gateway) => {
                        const url = `/gateway/${gateway.uuid}`;
                        const offline = gateway.lastSeenDate && moment(gateway.lastSeenDate) < moment().subtract(30, "minutes");
                        return (
                            <tr key={ gateway.id } className="table-clickable-row">
                                <td>
                                    <Link to={url}>
                                        {gateway.uuid}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={ url } className={offline ? "text-danger" : ""}>
                                        <DateFormatter date={ gateway.lastSeenDate }/>
                                    </Link>
                                </td>
                                <td>
                                    <Link to={ url }>
                                        { gateway.installation ? gateway.installation.name : (
                                            <i className="text-muted">Niet toegewezen</i>
                                        )}
                                    </Link>
                                </td>
                                <td className="text-center">
                                    <Link to={ url }>
                                        { gateway.devicesCount }
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    )
}

export default React.memo(Gateways);
