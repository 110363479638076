import React, {
    useState
} from "react";
import {
    Modal
} from "react-bootstrap";

function ChartModal({ show, handleClose, title, children }) {
    const [showing, setShowing] = useState(false);
    return (
        <Modal
            size="fullscreen"
            show={ show }
            onHide={ handleClose }
            onShow={ () => setShowing(true) }
            onExited={ () => setShowing(false) }
        >
            <Modal.Header closeButton>
                <Modal.Title className="flex-grow-1 d-flex flex-row align-items-center">
                    <i className="fas fa-chart-bar ml-2 mr-3"/>
                    { title }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="h-100">
                    { showing && children }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(ChartModal);
