import React, {
    useCallback,
    useState
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import NumberFormatter from "../../../../components/formatters/NumberFormatter";
import DateFormatter from "../../../../components/formatters/DateFormatter";
import EnergyMeterHistoryModal from "./EnergyMeterHistoryModal";
import DeviceIcon from "../../../../components/DeviceIcon";

function Row({ title, children }) {
    return (
        <div className="row">
            <div className="col-5 text-right">
                <b>{ title }</b>
            </div>
            <div className="col">
                { children }
            </div>
        </div>
    )
}

export function InstallationEnergyMeterCard({ energyMeter, onClickAddDevice, allowHistory, disabled }) {
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    const handleCloseHistoryModal = useCallback(() => {
        setShowHistoryModal(false);
    }, []);

    return (
        <div className="card mb-3">
            { allowHistory && (
                <EnergyMeterHistoryModal
                    energyMeter={ energyMeter }
                    show={ showHistoryModal }
                    handleClose={ handleCloseHistoryModal }
                />
            )}
            <div className="card-body">
                <div className="d-flex flex-row align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { energyMeter.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { energyMeter.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { energyMeter.serialNumber }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3 d-flex flex-row align-items-center">
                        { allowHistory && (
                            <OverlayTrigger overlay={
                                <Tooltip id={ `energyMeter-history-${energyMeter.id}` }>
                                    Bekijk historie
                                </Tooltip>
                            }>
                                <Button
                                    variant="link"
                                    className="py-0"
                                    onClick={ () => setShowHistoryModal(true) }
                                >
                                    <i className="fa-solid fa-history fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                        )}
                        <DeviceIcon deviceType="energyMeter"/>
                    </div>
                </div>
                <Row title="Functie">
                    { energyMeter.options !== null ? (
                        <React.Fragment>
                            { energyMeter.options }
                        </React.Fragment>
                    ) : "Unknown" }
                </Row>
                <Row title="Vermogen">
                    { energyMeter.powerAc !== null ? (
                        <React.Fragment>
                            <NumberFormatter number={ energyMeter.powerAc }/>W
                        </React.Fragment>
                    ) : "Unknown" }
                </Row>
                <Row title="Laatst gezien">
                    <DateFormatter date={ energyMeter.lastSeenDate }/>
                </Row>
                { onClickAddDevice && energyMeter.installationId === null && (
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => onClickAddDevice("energyMeter", energyMeter.id) }
                            disabled={ disabled }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Energiemeter toevoegen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

function InstallationEnergyMeterCards({ energyMeters, onClickAddDevice, disabled }) {
    return energyMeters.map((energyMeter) => (
        <InstallationEnergyMeterCard
            key={ energyMeter.id }
            energyMeter={ energyMeter }
            onClickAddDevice={ onClickAddDevice }
            disabled={ disabled }
        />
    ));
}

export default React.memo(InstallationEnergyMeterCards);
