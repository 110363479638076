import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import usePermissions from "./hooks/usePermissions";
import GridSenseNavbar from "./components/layout/GridSenseNavbar";
import GridSenseSidebar from "./components/layout/GridSenseSidebar";
import ScrollToTop from "./hooks/ScrollToTop";
import AppManager from "./AppManager";
import GridSensePageContainer from "./components/layout/GridSensePageContainer";

import Dashboard from "./pages/dashboard/Dashboard";
import EnergyPrices from "./pages/energy-prices/EnergyPrices";
import ImbalancePrices from "./pages/imbalance-prices/ImbalancePrices";
import InstallationAdd from "./pages/installations/InstallationAdd";
import Installations from "./pages/installations/Installations";
import InstallationDetail from "./pages/installations/detail/InstallationDetail";
import Gateways from "./pages/gateways/Gateways";
import GatewayDetail from "./pages/gateways/detail/GatewayDetail";
import EnergyHandlingModes from "./pages/energy-handling-modes/EnergyHandlingModes";
import UserAdd from "./pages/users/UserAdd";
import Users from "./pages/users/Users";
import Page404 from "./pages/Page404";
import Settings from "./pages/settings/Settings";

function AppRouter() {
    const permissions = usePermissions();
    return (
        <AppManager>
            <GridSenseNavbar/>
            <GridSenseSidebar/>

            <ScrollToTop/>

            <GridSensePageContainer>
                <Switch>
                    <Route path="/dashboard" component={Dashboard} />

                    <Route path={["/energy-prices/:date", "/energy-prices"]} component={EnergyPrices} />

                    <Route path={["/imbalance-prices/:date", "/imbalance-prices"]} component={ImbalancePrices} />

                    <Route path="/installations/add" component={InstallationAdd} />
                    <Route path="/installations" component={Installations} />
                    <Route path="/installation/:installationId" component={InstallationDetail} />

                    { permissions.globalAdmin && (
                        <Route path="/gateways" component={Gateways} />
                    )}
                    { permissions.globalAdmin && (
                        <Route path="/gateway/:uuid" component={GatewayDetail} />
                    )}

                    { permissions.globalAdmin && (
                        <Route path={["/energy-handling-modes/:date", "/energy-handling-modes"]} component={EnergyHandlingModes} />
                    )}

                    { permissions.manageCompanyUsers && (
                        <Route path="/users/add" component={UserAdd} />
                    )}
                    { permissions.manageCompanyUsers && (
                        <Route path="/users" component={Users} />
                    )}

                    <Route path="/settings" component={Settings} />

                    <Route path="/" exact>
                        <Redirect
                            to={{
                                pathname: "/dashboard",
                                state: { from: "/" }
                            }}
                        />
                    </Route>
                    <Route path="/" component={Page404} />
                </Switch>
            </GridSensePageContainer>
        </AppManager>
    )
}

export default AppRouter;
