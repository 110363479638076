import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

function SettingsPaymentInvoiceData() {
    const [invoiceData, setInvoiceData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setError(null);
        setInvoiceData(null);
        setSuccess(null);
        axios.post("/getInvoiceData")
            .then((response) => {
                if(response.data.valid) {
                    setInvoiceData(response.data.invoiceData);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    const onSubmit = useCallback(() => {
        setError(null);
        setSuccess(null);
        setLoading(true);
        axios.post("/setInvoiceData", {
            ...invoiceData,
            invoiceCompany: invoiceData.invoiceCompany ? 1 : 0
        })
            .then((response) => {
                if(response.data.valid) {
                    setSuccess("Facturatie gegevens zijn opgeslagen.");
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [invoiceData]);

    if(!invoiceData) {
        return (
            <Skeleton height={ 490 }/>
        )
    }
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Facturatie gegevens</h5>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { success && (
                    <Alert variant="success">{ success }</Alert>
                )}
                <div className="custom-control custom-checkbox mb-2">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="invoiceCompany"
                        checked={ invoiceData.invoiceCompany ?? false }
                        onChange={ (event) => {
                            setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoiceCompany: event.target.checked }))
                        } }
                        disabled={ loading }
                    />
                    <label className="custom-control-label" htmlFor="invoiceCompany">Bedrijf</label>
                </div>
                { invoiceData.invoiceCompany && (
                    <div className="form-group">
                        <label htmlFor="invoiceCompanyName">Bedrijfsnaam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="invoiceCompanyName"
                            value={ invoiceData.invoiceCompanyName ?? "" }
                            onChange={ (event) => {
                                setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoiceCompanyName: event.target.value }))
                            } }
                            disabled={ loading }
                        />
                    </div>
                )}
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <label htmlFor="invoiceFirstName">Voornaam</label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceFirstName"
                                value={ invoiceData.invoiceFirstName ?? "" }
                                onChange={ (event) => {
                                    setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoiceFirstName: event.target.value }))
                                } }
                                disabled={ loading }
                            />
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <label htmlFor="invoiceLastName">Achternaam</label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceLastName"
                                value={ invoiceData.invoiceLastName ?? "" }
                                onChange={ (event) => {
                                    setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoiceLastName: event.target.value }))
                                } }
                                disabled={ loading }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <label htmlFor="invoiceStreet">Straat</label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceStreet"
                                value={ invoiceData.invoiceStreet ?? "" }
                                onChange={ (event) => {
                                    setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoiceStreet: event.target.value }))
                                } }
                                disabled={ loading }
                            />
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <label htmlFor="invoiceHouseNumber">Huisnummer + toevoeging</label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceHouseNumber"
                                value={ invoiceData.invoiceHouseNumber ?? "" }
                                onChange={ (event) => {
                                    setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoiceHouseNumber: event.target.value }))
                                } }
                                disabled={ loading }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="form-group">
                            <label htmlFor="invoicePostalCode">Postcode</label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoicePostalCode"
                                value={ invoiceData.invoicePostalCode ?? "" }
                                onChange={ (event) => {
                                    setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoicePostalCode: event.target.value }))
                                } }
                                disabled={ loading }
                            />
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="form-group">
                            <label htmlFor="invoiceCity">Stad</label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceCity"
                                value={ invoiceData.invoiceCity ?? "" }
                                onChange={ (event) => {
                                    setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoiceCity: event.target.value }))
                                } }
                                disabled={ loading }
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="invoiceEmail">Factuur emailadres</label>
                    <input
                        type="text"
                        className="form-control"
                        id="invoiceEmail"
                        value={ invoiceData.invoiceEmail ?? "" }
                        onChange={ (event) => {
                            setInvoiceData((prevInvoiceData) => ({ ...prevInvoiceData, invoiceEmail: event.target.value }))
                        } }
                        disabled={ loading }
                    />
                </div>
                <div className="d-flex justify-content-end">
                    <Button
                        variant="primary"
                        onClick={ onSubmit }
                        disabled={ loading }
                    >
                        Opslaan
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default React.memo(SettingsPaymentInvoiceData);
