import React from "react";

import logo from "../img/logo.svg";
import logoWhite from "../img/logo-white.svg";

function Logo({ white, ...props }) {
    return (
        <img { ...props } src={ white ? logoWhite : logo } alt="GridSense"/>
    )
}

export default React.memo(Logo);
