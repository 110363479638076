import React from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

import InfoCardRow from "../../../components/InfoCardRow";
import TagPill from "../../../components/TagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import EuroFormatter from "../../../components/formatters/EuroFormatter";
import IBANFormatter from "../../../components/formatters/IBANFormatter";

function SettingsSubscriptionTransactionModal({ show, handleClose, transaction }) {
    if(!transaction) {
        return null;
    }
    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>
                    { transaction.type === "first" ? (
                        "Koppelingstransactie"
                    ) : transaction.type === "recurring" && (
                        "Abonnementstransactie"
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InfoCardRow title="Status" className="mb-3">
                    <TagPill color={ transaction.status.color }>
                        { transaction.status.name }
                    </TagPill>
                </InfoCardRow>
                <InfoCardRow title="Datum" className="mb-3">
                    <DateFormatter date={ transaction.paidDate ? transaction.paidDate : transaction.createdDate }/>
                </InfoCardRow>
                <InfoCardRow title="Totale kosten" className="mb-3">
                    <EuroFormatter price={ transaction.price }/>
                </InfoCardRow>
                { transaction.details?.consumerName && (
                    <InfoCardRow title="Rekeningnaam" className="mb-3">
                        { transaction.details.consumerName }
                    </InfoCardRow>
                )}
                { transaction.details?.consumerAccount && (
                    <InfoCardRow title="IBAN" className="mb-3">
                        <IBANFormatter iban={ transaction.details.consumerAccount }/>
                    </InfoCardRow>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(SettingsSubscriptionTransactionModal);
