import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import * as Sentry from "@sentry/react";

import ImageUploadArea from "../../../../components/ImageUploadArea";

function InstallationAddGatewayModal({ installation, show, handleClose, refreshDevices }) {
    const [uuid, setUUID] = useState("");
    const [image, setImage] = useState(null);
    const [imageUUID, setImageUUID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onShow = useCallback(() => {
        setUUID("");
        setImageUUID(null);
        setLoading(false);
        setError(null);
    }, []);

    const detectQRCode = useCallback(async (image) => {
        if(image.file === null) {
            return false;
        }

        const formData = new FormData();
        formData.append("file", image.file, image.file.name);

        try {
            const response = await axios.post("/detectUUIDQRFromImage", formData, {
                transformRequest: (data, headers) => {
                    headers.setContentType(undefined);
                    return data;
                }
            });
            return response.data.uuid;
        } catch(requestError) {
            Sentry.captureException(requestError);
            return null;
        }
    }, []);

    const onImageChange = useCallback(async (imageList) => {
        if(!imageList || imageList.length === 0) {
            setImage(null);
            setImageUUID(null);
        } else {
            setImage(imageList[0]);
            setLoading(true);
            const result = await detectQRCode(imageList[0]);
            setLoading(false);
            if(result === null) {
                setError("De QR Code kon niet worden herkend.");
                return;
            }
            setImageUUID(result);
        }
    }, [detectQRCode]);

    const addGateway = useCallback(async () => {
        setLoading(true);
        try {
            await axios.post("/addGatewayToInstallation", {
                installationId: installation.id,
                uuid: imageUUID || uuid
            });
            handleClose();
            refreshDevices();
        } catch(requestError) {
            setLoading(false);
            switch(requestError?.response?.data?.error) {
                case "DOESNT_EXIST":
                    setError("Kan geen gateway vinden met dit serienummer. Is deze gateway al eens verbonden met internet?");
                    break;
                case "ALREADY_ASSIGNED":
                    const installationName = requestError.response.data.installation?.name;
                    if(installationName) {
                        setError("Deze gateway is al gekoppeld met installatie '" + installationName + "'.");
                    } else {
                        setError("Deze gateway is al gekoppeld met een andere installatie.");
                    }
                    break;
                default:
                    Sentry.captureException(requestError);
                    setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                    break;
            }
        }
    }, [installation, uuid, imageUUID, refreshDevices, handleClose]);

    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onShow }>
            <Modal.Header closeButton>
                <Modal.Title>Gateway toevoegen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { imageUUID === null && (
                    <div className="form-group">
                        <label htmlFor="uuid">Handmatig serienummer toevoegen</label>
                        <input
                            type="text"
                            className="form-control"
                            name="uuid"
                            value={ uuid }
                            onChange={ (event) => setUUID(event.target.value) }
                            disabled={ loading }
                        />
                    </div>
                )}
                <ImageUploadArea
                    images={ image ? [image] : [] }
                    onChange={ onImageChange }
                    loading={ loading }
                    emptyState="Klik hier om een foto met QR Code toe te voegen"
                />
                { imageUUID && (
                    <Alert variant="success" className="mt-2 mb-0">
                        QR Code herkend: { imageUUID }
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={ addGateway } disabled={ loading }>
                    Toevoegen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(InstallationAddGatewayModal);
