import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import termsOfService from "../../../assets/algemene-voorwaarden-gridsense.pdf";

function SettingsPaymentDirectDebitSetupModal({ show, handleClose }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [termsOfServiceChecked, setTermsOfServiceChecked] = useState(false);

    useEffect(() => {
        if(!show) {
            return;
        }
        setTermsOfServiceChecked(false);
    }, [show]);

    const onSetup = useCallback(() => {
        if(!termsOfServiceChecked) {
            setError("U dient akkoord te gaan met de algemene voorwaarden.")
            return;
        }
        setLoading(true);
        setError(null);
        axios.get("/setupDirectDebit")
            .then((response) => {
                if(response.data.valid) {
                    window.location.href = response.data.redirectUrl;
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                setLoading(false);
            });
    }, [termsOfServiceChecked]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Automatische incasso instellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="privacyPolicyCheckbox"
                        disabled={ loading }
                        onChange={ (event) => setTermsOfServiceChecked(event.target.checked) }
                        checked={ termsOfServiceChecked }
                    />
                    <label className="custom-control-label" htmlFor="privacyPolicyCheckbox">
                        Ik ga akkoord met de{" "}
                        <a href={ termsOfService } target="_blank" rel="noopener noreferrer">algemene voorwaarden</a>.
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={ onSetup } disabled={ loading }>
                    { loading && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Instellen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(SettingsPaymentDirectDebitSetupModal);
