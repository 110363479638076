import React from "react";

import ChartCard from "../../../../../components/charts/ChartCard";
import ChartBatteryHistory from "../../../../../charts/ChartBatteryHistory";

function InstallationBatteryHistoryChartCard({ installation, startDate, endDate }) {
    return (
        <ChartCard
            title="Batterij laadtoestand (SoE)"
            height={ 300 }
        >
            <ChartBatteryHistory
                startDate={ startDate }
                endDate={ endDate }
                installationId={ installation?.id }
            />
        </ChartCard>
    );
}

export default React.memo(InstallationBatteryHistoryChartCard);
