import React, {
    useState
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import ChartModal from "./ChartModal";

function ChartCard({ title, cardTitle, children, height = 500 }) {
    const [showModal, setShowModal] = useState(false);
    return (
        <React.Fragment>
            <ChartModal
                title={ title }
                show={ showModal }
                handleClose={ () => setShowModal(false) }
            >
                { children }
            </ChartModal>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="card-title">{ cardTitle ? cardTitle : title }</h5>
                        </div>
                        <div className="ml-2">
                            <OverlayTrigger overlay={
                                <Tooltip id={`tooltip-chart-fullscreen`}>
                                    Open grafiek volledig scherm
                                </Tooltip>
                            }>
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={ () => setShowModal(true) }
                                >
                                    <i className="fas fa-expand fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="w-100" style={{ height: `${height}px` }}>
                        { children }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ChartCard);
