import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

function SettingsPaymentDirectDebitRevokeModal({ show, handleClose, updateState }) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onRevoke = useCallback(() => {
        setLoading(true);
        setError(null);
        axios.get("/revokeDirectDebit")
            .then((response) => {
                if(response.data.valid) {
                    updateState(response.data);
                    handleClose();
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                setLoading(false);
            });
    }, [handleClose, updateState]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title className="text-danger">
                    Automatische incasso loskoppelen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p>
                    Als je de automatische incasso loskoppelt kunt u een nieuwe rekening koppelen. Indien u geen nieuwe
                    rekening koppelt zal bij een openstaande rekening GridSense stoppen met functioneren.
                </p>
                <p className="mb-0">
                    Koppel hierna een nieuwe rekening om te voorkomen dat GridSense uw omvormer en/of batterij niet meer aanstuurt.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ loading }
                >
                    Annuleren
                </Button>
                <Button
                    variant="danger"
                    onClick={ onRevoke }
                    disabled={ loading }
                >
                    Loskoppelen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(SettingsPaymentDirectDebitRevokeModal);
