import {
    useMemo
} from "react";

function NumberFormatter({ number, minimumFractionDigits = undefined }) {
    const formatter = useMemo(() => {
        return new Intl.NumberFormat("nl-NL", {
            minimumFractionDigits
        });
    }, [minimumFractionDigits]);
    return formatter.format(number);
}

export default NumberFormatter;
