import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import usePermissions from "../../hooks/usePermissions";
import Page404 from "../Page404";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import UserForm from "./UserForm";

function UserAdd() {
    const history = useHistory();
    const permissions = usePermissions();
    const authentication = useContext(AuthenticatedUserContext);

    const [settings, setSettings] = useState({
        name: "",
        email: "",

        password: "",
        passwordConfirmation: ""
    });
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [company, setCompany] = useState(-1);

    useEffect(() => {
        if(!authentication.user) {
            return;
        }
        if(authentication.user.companies.length === 1) {
            setCompany(authentication.user.companies[0].id);
        }
    }, [authentication]);

    const onSubmit = useMemo(() => {
        return async () => {
            const {
                name,
                email,
                password,
                passwordConfirmation
            } = settings;
            if(`${company}` === "-1") {
                setError("Ongeldig bedrijf ingevuld.");
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }
            if(password !== passwordConfirmation) {
                setError("Wachtwoorden komen niet overeen.");
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }
            try {
                setSaving(true);
                setError(null);
                await axios.post("/addCompanyUser", {
                    companyId: company,
                    name,
                    email,
                    password
                });
                history.push(`/users`);
            } catch(requestError) {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
            setSaving(false);
        }
    }, [settings, company, history]);

    if(!permissions.manageCompanyUsers) {
        return (
            <Page404/>
        )
    }
    return (
        <React.Fragment>
            <Title preTitle="Overzicht">
                Gebruiker aanmaken
            </Title>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { authentication.user.companies.length !== 1 && (
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Bedrijf</h5>
                        <div className="form-group mb-0">
                            <label htmlFor="company">Bedrijf</label>
                            <select
                                className="form-control"
                                value={ company }
                                onChange={ event => setCompany(event.target.value) }
                            >
                                <option value="-1"/>
                                { authentication.user && authentication.user.companies.map(company => (
                                    <option
                                        key={ company.id }
                                        value={ company.id }
                                    >
                                        { company.name }
                                    </option>
                                ))}
                            </select>
                            <small className="form-text text-muted">
                                Selecteer hier het bedrijf waaronder de gebruiker wordt aangemaakt.
                            </small>
                        </div>
                    </div>
                </div>
            )}
            <UserForm
                settings={ settings }
                setSettings={ setSettings }
                saving={ saving }
            />
            <div className="d-flex justify-content-end">
                <Button
                    variant="primary"
                    onClick={ onSubmit }
                    disabled={ saving }
                >
                    Opslaan
                </Button>
            </div>
        </React.Fragment>
    );
}

export default React.memo(UserAdd);
