import React from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import {
    BrowserRouter
} from "react-router-dom";
import {
    CSSTransition
} from "react-transition-group";

import "./scss/gridsense.scss";

import AuthenticatedUserContext from "./context/AuthenticatedUserContext";
import Logo from "./components/Logo";
import RootRouter from "./RootRouter";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWhiteOverlayOverride: false,
            showInitialLoadingIndicator: false,
            authenticatedUser: {
                user: undefined,
                login: this.login.bind(this),
                logout: this.logout.bind(this),
                getErrorMessage: this.getErrorMessage.bind(this)
            }
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.state.authenticatedUser.user === undefined) {
                this.setState({ showInitialLoadingIndicator: true });
            }
        }, 100);
        this.getSession();
    }

    setUser(user) {
        if(user) {
            console.log("Logged in as user " + user.name + ".");
            Sentry.setUser({
                id: user.id,
                name: user.name,
                email: user.email
            });
        } else {
            console.log("Not logged in.");
        }
        this.setState((previousState) => {
            return { authenticatedUser: { ...previousState.authenticatedUser, user: user } }
        });
    }

    showWhiteOverlayOverridden(value, stateCheck = () => { return false }) {
        this.setState({ showWhiteOverlayOverride: value, showInitialLoadingIndicator: false });
        if(value) {
            setTimeout(() => {
                if(stateCheck()) {
                    this.setState({ showInitialLoadingIndicator: true });
                }
            }, 100);
        }
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "INVALID_CREDENTIALS":
                return "Foutieve gebruikersnaam of wachtwoord.";
            case "ACCOUNT_BLOCKED":
                return "Te veel foutieve inlogpogingen. Account is geblokkeerd voor 15 minuten.";
            case "PASSWORD_TOO_SHORT":
                return "Wachtwoord moet minimaal 8 tekens lang zijn.";
            case "PASSWORDS_DONT_MATCH":
                return "Wachtwoorden komen niet overeen.";
            case "EMAIL_IN_USE":
                return "Er bestaat al een account met dit emailadres.";
            case "NAME_TOO_SHORT":
                return "Naam moet minimaal 2 tekens lang zijn.";
            default:
                return "Er ging iets fout. Probeer het later opnieuw.";
        }
    }

    login(state, onErrorOccurred) {
        const {
            email,
            password
        } = state;

        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user === null });
        if(email.length === 0 || password.length === 0) {
            setTimeout(() => {
                onErrorOccurred(this.getErrorMessage("INVALID_CREDENTIALS"));
                this.showWhiteOverlayOverridden(false);
            }, 1000);
            return;
        }
        axios.post("/login", { email, password, useCookie: true, deviceType: "desktop" })
            .then((response) => {
                setTimeout(() => {
                    if(response.data.valid) {
                        this.setUser(response.data.user);
                    } else {
                        onErrorOccurred(this.getErrorMessage(response.data.error));
                    }
                }, 1000);
            })
            .catch((error) => {
                console.error(error);
                setTimeout(() => {
                    onErrorOccurred(this.getErrorMessage());
                }, 1000);
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 1000);
            });
    }

    getSession() {
        axios.get("/getSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setUser(response.data.user);
                } else {
                    this.setUser(null);
                }
            })
            .catch((error) => {
                console.error(error);
                this.setUser(null);
            })
    }

    logout() {
        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user !== null });
        axios.get("/logout")
            .then((response) => {
                setTimeout(() => {
                    if(response.data.valid) {
                        this.setUser(null);
                    } else {
                        // TODO: Display pop-over error message.
                    }
                }, 500);
            })
            .catch((error) => {
                console.error(error);
                // TODO: Display pop-over error message.
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 500);
            });
    }

    render() {
        return (
            <AuthenticatedUserContext.Provider value={ this.state.authenticatedUser }>

                <CSSTransition in={ this.state.authenticatedUser.user === undefined || this.state.showWhiteOverlayOverride }
                               timeout={500} classNames="initial-load-background" unmountOnExit>
                    <div style={{
                        position: "fixed",
                        top: 0,
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F9FBFD",
                        zIndex: 1100
                    }}>
                        <CSSTransition in={ this.state.showInitialLoadingIndicator } timeout={500} classNames="initial-load-logo" appear={true} mountOnEnter>
                            <Logo
                                className="initial-load-logo-enter px-3"
                                style={{ height: "125px", width: "100%" }}
                            />
                        </CSSTransition>
                    </div>
                </CSSTransition>

                { this.state.authenticatedUser.user !== undefined && (
                    <BrowserRouter>
                        <RootRouter authenticated={ this.state.authenticatedUser.user !== null }/>
                    </BrowserRouter>
                )}

            </AuthenticatedUserContext.Provider>
        );
    }
}

export default App;
