import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import DateFormatter from "../../../components/formatters/DateFormatter";
import SendUserInstallationInviteModal from "./modal/SendUserInstallationInviteModal";
import ResendUserInstallationInviteModal from "./modal/ResendUserInstallationInviteModal";

function InstallationDetailAccess({ installation }) {
    const [users, setUsers] = useState(null);
    const [invites, setInvites] = useState(null);
    const [error, setError] = useState(null);
    const [showSendInviteModal, setShowSendInviteModal] = useState(false);
    const [showResendInviteModal, setShowResendInviteModal] = useState(false);
    const [inviteToResend, setInviteToResend] = useState(null);

    const getUsers = useMemo(() => {
        return async () => {
            setError(null);
            setUsers(null);
            try {
                const response = await axios.post("/getUsersForInstallation", { installationId: installation.id });
                setUsers(response.data.users);
            } catch(error) {
                console.error(error);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            }
        }
    }, [installation]);
    const getInvites = useMemo(() => {
        return async () => {
            setError(null);
            setInvites(null);
            try {
                const response = await axios.post("/getUserInstallationInvites", { installationId: installation.id });
                setInvites(response.data.invites);
            } catch(error) {
                console.error(error);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            }
        }
    }, [installation]);
    const refresh = useMemo(() => {
        return () => {
            getUsers();
            getInvites();
        }
    }, [getUsers, getInvites]);

    useEffect(() => {
        if(!installation) {
            return;
        }
        refresh();
    }, [installation, refresh]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!invites || !users) {
        return (
            <Loading/>
        )
    }
    return (
        <React.Fragment>
            <SendUserInstallationInviteModal
                installation={ installation }
                show={ showSendInviteModal }
                handleClose={ () => setShowSendInviteModal(false) }
                onInviteSent={ refresh }
            />
            <ResendUserInstallationInviteModal
                installation={ installation }
                inviteToResend={ inviteToResend }
                show={ showResendInviteModal }
                handleClose={ () => setShowResendInviteModal(false) }
                onInviteResent={ refresh }
            />
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <h5 className="mb-2">Gebruikers</h5>
                    <Table hover>
                        <thead>
                        <tr>
                            <th>Naam</th>
                            <th>E-mailadres</th>
                            <th>Rol</th>
                        </tr>
                        </thead>
                        <tbody>
                        { users.length === 0 ? (
                            <tr>
                                <td colSpan={ 3 }>Er zijn nog geen gebruikers met toegang.</td>
                            </tr>
                        ) : users.map((user) => (
                            <tr key={ user.id }>
                                <td>{ user.name }</td>
                                <td>{ user.email }</td>
                                <td>{ user.role.name }</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div className="d-flex flex-column flex-lg-row mb-2 mt-4">
                        <div className="flex-grow-1 h-100 d-flex align-items-end">
                            <h5>Openstaande uitnodigingen</h5>
                        </div>
                        <div>
                            <Button variant="primary" size="sm" onClick={ () => setShowSendInviteModal(true) }>
                                <i className="fa-solid fa-plus mr-2"/>
                                Uitnodiging versturen
                            </Button>
                        </div>
                    </div>
                    <Table hover>
                        <thead>
                        <tr>
                            <th>Naam</th>
                            <th>E-mailadres</th>
                            <th>Verstuurd door</th>
                            <th>Verstuurd op</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        { invites.length === 0 ? (
                            <tr>
                                <td colSpan={ 4 }>Er zijn geen openstaande uitnodigingen.</td>
                            </tr>
                        ) : invites.map((invite) => (
                            <tr key={ invite.id }>
                                <td>{ invite.name }</td>
                                <td>{ invite.email }</td>
                                <td>{ invite.author.name }</td>
                                <td><DateFormatter date={ invite.date }/></td>
                                <td className="text-right">
                                    <OverlayTrigger overlay={
                                        <Tooltip id="resend-invite">
                                            Uitnodiging opnieuw verzenden
                                        </Tooltip>
                                    }>
                                        <Button
                                            variant="link"
                                            size="sm"
                                            onClick={ () => {
                                                setInviteToResend(invite);
                                                setShowResendInviteModal(true);
                                            }}
                                        >
                                            <i className="fa-solid fa-redo"/>
                                        </Button>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(InstallationDetailAccess);
