import React from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import NumberFormatter from "../../../../components/formatters/NumberFormatter";
import DateFormatter from "../../../../components/formatters/DateFormatter";
import StatusPill from "../../../../components/StatusPill";
import usePermissions from "../../../../hooks/usePermissions";
import DeviceIcon from "../../../../components/DeviceIcon";

function Row({ title, children }) {
    return (
        <div className="row">
            <div className="col-5 text-right">
                <b>{ title }</b>
            </div>
            <div className="col">
                { children }
            </div>
        </div>
    )
}

export function InstallationInverterCard({ inverter, onClickInverterState, onClickInverterEnergyAlgorithm, onClickAddDevice, disabled }) {
    const permissions = usePermissions();

    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex flex-row">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { inverter.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { inverter.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { inverter.serialNumber }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3">
                        <DeviceIcon deviceType="inverter"/>
                    </div>
                </div>
                <Row title="Status">
                    { inverter.status?.name ?? "Unknown" }
                </Row>
                { (permissions.manageInstallations && inverter.version) ? (
                    <Row title="Versie">
                        <div>
                            { inverter.version }
                        </div>
                    </Row>
                ) : null }
                <Row title="Sturing">
                    <StatusPill value={ inverter.preferredEnergyHandlingMode }/>
                    { onClickInverterState && (
                        <OverlayTrigger overlay={
                            <Tooltip id={`inverter-preferredstate`}>
                                Wijzig de sturing van deze omvormer
                            </Tooltip>
                        }>
                            <Button variant="link" size="sm" className="py-0" onClick={ () => onClickInverterState(inverter) } disabled={ disabled }>
                                <i className="fa-solid fa-pen"/>
                            </Button>
                        </OverlayTrigger>
                    )}
                </Row>
                <Row title="Algoritme">
                    { inverter.energyAlgorithm?.name ?? "Niet ingesteld" }
                    { onClickInverterEnergyAlgorithm && (
                        <OverlayTrigger overlay={
                            <Tooltip id={`inverter-preferredstate`}>
                                Wijzig het algoritme van deze omvormer
                            </Tooltip>
                        }>
                            <Button variant="link" size="sm" className="py-0" onClick={ () => onClickInverterEnergyAlgorithm(inverter) } disabled={ disabled }>
                                <i className="fa-solid fa-pen"/>
                            </Button>
                        </OverlayTrigger>
                    )}
                </Row>
                <Row title="AC Power">
                    { inverter.powerAc !== null ? (
                        <React.Fragment>
                            <NumberFormatter number={ inverter.powerAc }/> W
                        </React.Fragment>
                    ) : "Unknown" }
                </Row>
                { (inverter.voltageAcL1 || inverter.voltageAcL2 || inverter.voltageAcL3) ? (
                    <Row title="AC Spanning">
                        <div className="d-flex flex-row flex-wrap">
                            { [inverter.voltageAcL1, inverter.voltageAcL2, inverter.voltageAcL3].map((voltage, index) => {
                                if(voltage === null || voltage < 50) {
                                    return null;
                                }
                                return (
                                    <div className="mr-3" key={ index }>
                                        <span className="text-muted">
                                            L{ index + 1 }:{" "}
                                        </span>
                                        <NumberFormatter number={ voltage }/> V
                                    </div>
                                );
                            })}
                        </div>
                    </Row>
                ) : null }
                <Row title="DC Power">
                    { inverter.powerDc !== null ? (
                        <React.Fragment>
                            <NumberFormatter number={ inverter.powerDc }/> W
                        </React.Fragment>
                    ) : "Unknown" }
                </Row>
                <Row title="Laatst gezien">
                    <DateFormatter date={ inverter.lastSeenDate }/>
                </Row>
                { onClickAddDevice && inverter.installationId === null && (
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => onClickAddDevice("inverter", inverter.id) }
                            disabled={ disabled }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Omvormer toevoegen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

function InstallationInverterCards({ inverters, onClickInverterState, onClickAddDevice, disabled }) {
    return inverters.map((inverter) => (
        <InstallationInverterCard
            key={inverter.id}
            inverter={inverter}
            onClickInverterState={onClickInverterState}
            onClickAddDevice={onClickAddDevice}
            disabled={disabled}
        />
    ));
}

export default React.memo(InstallationInverterCards);
