import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import DateFormatter from "../../../components/formatters/DateFormatter";
import InstallationAddGatewayModal from "./modal/InstallationAddGatewayModal";
import InstallationGatewayRestartSoftwareModal from "./modal/InstallationGatewayRestartSoftwareModal";
import DeviceIcon from "../../../components/DeviceIcon";
import InstallationGatewaySettingsModal from "./modal/gateway-settings/InstallationGatewaySettingsModal";

function Row({ title, children }) {
    return (
        <div className="row">
            <div className="col-5 text-right">
                <b>{ title }</b>
            </div>
            <div className="col">
                { children }
            </div>
        </div>
    )
}

function InstallationDetailGateways({ installation }) {
    const [gateways, setGateways] = useState(null);
    const [error, setError] = useState(null);

    const [showAddGatewayModal, setShowAddGatewayModal] = useState(false);

    const [showRestartSoftwareModal, setShowRestartSoftwareModal] = useState(false);
    const [restartSoftwareModalGateway, setRestartSoftwareModalGateway] = useState(null);

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [settingsModalGateway, setSettingsModalGateway] = useState(null);

    const loadDevices = useCallback(() => {
        if(!installation) {
            return;
        }
        setGateways(null);
        setError(null);
        axios.post("/getGatewaysForInstallation", { installationId: installation.id })
            .then((response) => {
                if(response.data.valid) {
                    setGateways(response.data.gateways);
                } else {
                    setError("Er is iets fout gegaan. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            });
    }, [installation]);
    useEffect(() => {
        loadDevices();
    }, [loadDevices]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    if(!gateways) {
        return (
            <Loading/>
        )
    }
    return (
        <React.Fragment>
            <InstallationAddGatewayModal
                installation={ installation }
                show={ showAddGatewayModal }
                handleClose={ () => setShowAddGatewayModal(false) }
                refreshDevices={ loadDevices }
            />
            <InstallationGatewayRestartSoftwareModal
                show={ showRestartSoftwareModal }
                handleClose={ () => setShowRestartSoftwareModal(false) }
                gateway={ restartSoftwareModalGateway }
            />
            <InstallationGatewaySettingsModal
                show={ showSettingsModal }
                handleClose={ () => setShowSettingsModal(false) }
                gateway={ settingsModalGateway }
                installation={ installation }
            />
            { gateways.length === 0 ? (
                <div className="text-center">
                    <h1><i className="fa-solid fa-router"/></h1>
                    <h4>Geen gateways</h4>
                    <p className="mb-0">Er zijn nog geen gateways toegevoegd aan deze installatie.</p>
                    <p>Gateways zijn het communicatie middel tussen GridSense en de omvormer.</p>
                    <Button variant="primary" onClick={ () => setShowAddGatewayModal(true) }>
                        <i className="fa-solid fa-plus mr-2"/>
                        Gateway toevoegen
                    </Button>
                </div>
            ) : (
                <React.Fragment>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="mb-3" size="sm" onClick={ () => setShowAddGatewayModal(true) }>
                            <i className="fa-solid fa-plus mr-2"/>
                            Gateway toevoegen
                        </Button>
                    </div>
                    <div className="row">
                        { gateways.map((gateway) => (
                            <div className="col-lg-6" key={ gateway.id }>
                                <div className="card mb-3" key={ gateway.id }>
                                    <div className="card-body">
                                        <div className="d-flex flex-row">
                                            <div className="flex-grow-1">
                                                <h5 className="card-title">
                                                    Gateway
                                                    <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                                        { gateway.uuid }
                                                    </div>
                                                </h5>
                                            </div>
                                            <div className="ml-3">
                                                <DeviceIcon deviceType="gateway"/>
                                            </div>
                                        </div>
                                        <Row title="Laatst gezien">
                                            <DateFormatter date={ gateway.lastSeenDate }/>
                                        </Row>

                                        { installation.userRole.permissions.manageInstallationDevices && (
                                            <div className="d-flex flex-row justify-content-end mt-2">
                                                <OverlayTrigger overlay={
                                                    <Tooltip id={ `settings-${gateway.id}` }>
                                                        Instellingen wijzigen
                                                    </Tooltip>
                                                }>
                                                    <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        onClick={ () => {
                                                            setSettingsModalGateway(gateway);
                                                            setShowSettingsModal(true);
                                                        }}
                                                        className="mr-2"
                                                    >
                                                        <i className="fa-solid fa-gear"/>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={
                                                    <Tooltip id={ `restart-software-${gateway.id}` }>
                                                        Herstart software
                                                    </Tooltip>
                                                }>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={ () => {
                                                            setRestartSoftwareModalGateway(gateway);
                                                            setShowRestartSoftwareModal(true);
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-rotate-right"/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(InstallationDetailGateways);
