import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import BankAccountCard from "../../../components/BankAccountCard";
import SettingsPaymentDirectDebitSetupModal from "../modal/SettingsPaymentDirectDebitSetupModal";
import SettingsPaymentDirectDebitRevokeModal from "../modal/SettingsPaymentDirectDebitRevokeModal";

import termsOfService from "../../../assets/algemene-voorwaarden-gridsense.pdf";

function SettingsPaymentDirectDebit() {
    const [state, setState] = useState(null);
    const [error, setError] = useState(null);

    const [showSetupModal, setShowSetupModal] = useState(false);
    const [showRevokeModal, setShowRevokeModal] = useState(false);

    useEffect(() => {
        setError(null);
        axios.get("/getDirectDebit")
            .then((response) => {
                if(response.data.valid) {
                    setState(response.data);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            });
    }, []);

    if(!state) {
        return (
            <div className="mb-3">
                <Skeleton height={ 150 }/>
            </div>
        )
    }
    return (
        <div className="card mb-3">
            <SettingsPaymentDirectDebitSetupModal
                show={ showSetupModal }
                handleClose={ () => setShowSetupModal(false) }
            />
            <SettingsPaymentDirectDebitRevokeModal
                show={ showRevokeModal }
                handleClose={ () => setShowRevokeModal(false) }
                updateState={ setState }
            />
            <div className="card-body">
                <h5 className="card-title">Automatische incasso</h5>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { state.setup ? (
                    <React.Fragment>
                        <p>Automatische incasso is ingesteld.</p>
                        { state.mandate?.details && (
                            <div className="mb-3">
                                <BankAccountCard
                                    name={ state.mandate.details.consumerName }
                                    iban={ state.mandate.details.consumerAccount }
                                />
                            </div>
                        )}
                        <Button
                            type="button"
                            variant="danger"
                            onClick={ () => setShowRevokeModal(true) }
                        >
                            Loskoppelen
                        </Button>
                        <p className="card-text text-muted mt-3">
                            Voor het gebruik van automatische incasso gaat u akkoord met de{" "}
                            <a href={ termsOfService } target="_blank" rel="noopener noreferrer">algemene voorwaarden</a>.
                        </p>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { state.invoiceDataValid ? (
                            <p>Automatische incasso is nog niet ingesteld.</p>
                        ) : (
                            <p>
                                Facturatie gegevens zijn nog niet ingediend. Hieronder dienen eerst de facturatie
                                gegevens ingevuld te worden voordat automatische incasso ingesteld kan worden.
                            </p>
                        )}
                        <Button
                            type="button"
                            variant="primary"
                            disabled={ !state.invoiceDataValid }
                            onClick={ () => setShowSetupModal(true) }
                        >
                            Instellen
                        </Button>
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default React.memo(SettingsPaymentDirectDebit);
