import React from "react";

import NumberFormatter from "../../../../../../components/formatters/NumberFormatter";
import BatteryIcon from "../../../../../../components/BatteryIcon";

function InstallationEnergyListLayout({ data }) {
    return (
        <React.Fragment>
            { data.solarPower !== null && (
                <div>
                    <span style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                        <i className="fa-solid fa-solar-panel fa-fw mr-3"/>
                        <NumberFormatter number={ Math.round(data.solarPower) }/>{" "}
                        <small className="text-muted">W</small>
                    </span>
                </div>
            )}
            { data.batteryPower !== null && (
                <div className="d-flex">
                    <div style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                        <BatteryIcon soe={ data.batterySoe } className="fa-fw mr-3"/>
                    </div>
                    <div>
                        <span style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                            <NumberFormatter number={ Math.round(data.batteryPower) }/>{" "}
                            <small className="text-muted">W</small>
                        </span>
                        <br/>
                        <span className="text-muted">
                            { data.batteryPower === 0 ? "Standby" : (data.batteryPower > 0 ? "Laden" : "Ontladen") }
                            { data.batterySoe ? (
                                <React.Fragment>
                                    {" "}(<NumberFormatter number={ Math.round(data.batterySoe) }/>%)
                                </React.Fragment>
                            ) : (" (0%)")}
                        </span>
                    </div>
                </div>
            )}
            { data.gridPower !== null && (
                <div className="d-flex">
                    <div style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                        <i className="fa-solid fa-utility-pole fa-fw mr-3"/>
                    </div>
                    <div>
                        <span style={{ fontSize: "1.3rem", fontWeight: 500 }}>
                            <NumberFormatter number={Math.round(data.gridPower)}/>{" "}
                            <small className="text-muted">W</small>
                        </span>
                        <br/>
                        <span className="text-muted">
                            {data.gridPower === 0 ? "Geen" : (data.gridPower > 0 ? "Import" : "Export")}
                        </span>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(InstallationEnergyListLayout);
