import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import InfoCardRow from "../../components/InfoCardRow";
import DateFormatter from "../../components/formatters/DateFormatter";
import EuroFormatter from "../../components/formatters/EuroFormatter";
import SettingsSubscriptionTransactionModal from "./modal/SettingsSubscriptionTransactionModal";
import TagPill from "../../components/TagPill";

function SettingsInvoices() {
    const [transactions, setTransactions] = useState(null);
    const [error, setError] = useState(null);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [modalTransaction, setModalTransaction] = useState(null);

    useEffect(() => {
        setTransactions(null);
        setError(null);
        axios.get("/getSubscriptionTransactions")
            .then((response) => {
                if(response.data.valid) {
                    setTransactions(response.data.subscriptionTransactions);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            });
    }, []);

    const onShowTransactionModal = useCallback((transaction) => {
        setModalTransaction(transaction);
        setShowTransactionModal(true);
    }, []);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!transactions) {
        return (
            <Skeleton height={ 150 } count={ 5 }/>
        )
    }
    if(transactions.length === 0) {
        return (
            <div className="text-center">
                <h1><i className="fa-solid fa-receipt"/></h1>
                <h4>Geen facturen</h4>
                <p>Er zijn nog geen transacties uitgevoerd.</p>
            </div>
        );
    }
    const columnClassName = "col-md-6 col-lg-4 col-xl-3";
    return (
        <React.Fragment>
            <SettingsSubscriptionTransactionModal
                show={ showTransactionModal }
                transaction={ modalTransaction }
                handleClose={ () => setShowTransactionModal(false) }
            />
            { transactions.map((transaction) => (
                <div
                    className="card mb-2"
                    key={ transaction.id }
                >
                    <div className="card-body">
                        <h5 className="card-title">
                            { transaction.type === "first" ? (
                                "Koppelingstransactie"
                            ) : transaction.type === "recurring" && (
                                "Abonnementstransactie"
                            )}
                        </h5>
                        <div className="row">
                            <div className={ columnClassName }>
                                <InfoCardRow title="Status">
                                    <TagPill color={ transaction.status.color }>
                                        { transaction.status.name }
                                    </TagPill>
                                </InfoCardRow>
                            </div>
                            <div className={ columnClassName }>
                                <InfoCardRow title="Datum">
                                    <DateFormatter date={ transaction.paidDate ? transaction.paidDate : transaction.createdDate }/>
                                </InfoCardRow>
                            </div>
                            <div className={ columnClassName }>
                                <InfoCardRow title="Totale kosten">
                                    <EuroFormatter price={ transaction.price }/>
                                </InfoCardRow>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button
                                variant="primary"
                                size="sm"
                                className="mt-2"
                                onClick={ () => onShowTransactionModal(transaction) }
                            >
                                Meer informatie
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
}

export default React.memo(SettingsInvoices);
