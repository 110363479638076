import React from "react";
import {
    Button
} from "react-bootstrap";

import NumberFormatter from "../../../../components/formatters/NumberFormatter";
import DateFormatter from "../../../../components/formatters/DateFormatter";
import StatusPill from "../../../../components/StatusPill";
import DeviceIcon from "../../../../components/DeviceIcon";

function Row({ title, children }) {
    return (
        <div className="row">
            <div className="col-5 text-right">
                <b>{ title }</b>
            </div>
            <div className="col">
                { children }
            </div>
        </div>
    )
}

export function InstallationEVChargerCard({
    evCharger,
    // onClickEVChargerState,
    onClickAddDevice,
    disabled,
}) {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex flex-row">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { evCharger.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { evCharger.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { evCharger.serialNumber }
                            </div>
                        </h5>
                    </div>
                    <div className="ml-3">
                        <DeviceIcon deviceType="evCharger"/>
                    </div>
                </div>
                <Row title="Status">
                    { evCharger.status?.name ?? "Unknown" }
                </Row>
                <Row title="Sturing">
                    <StatusPill value={ evCharger.mode }/>
                    {/*{ onClickEVChargerState && (*/}
                    {/*    <OverlayTrigger overlay={*/}
                    {/*        <Tooltip id={`evcharger-preferredstate`}>*/}
                    {/*            Wijzig de sturing van deze omvormer*/}
                    {/*        </Tooltip>*/}
                    {/*    }>*/}
                    {/*        <Button variant="link" size="sm" className="py-0" onClick={ () => onClickEVChargerState(evCharger) } disabled={ disabled }>*/}
                    {/*            <i className="fa-solid fa-pen"/>*/}
                    {/*        </Button>*/}
                    {/*    </OverlayTrigger>*/}
                    {/*)}*/}
                </Row>
                <Row title={ evCharger.powerDc !== null ? "DC Power" : "AC Power" }>
                    { (evCharger.powerDc !== null || evCharger.powerAc !== null) ? (
                        <React.Fragment>
                            <NumberFormatter number={ evCharger.powerDc ?? evCharger.powerAc }/> W
                        </React.Fragment>
                    ) : "Unknown"}
                </Row>
                { (evCharger.voltageAcL1 || evCharger.voltageAcL2 || evCharger.voltageAcL3) && (
                    <Row title="AC Spanning">
                        <div className="d-flex flex-row flex-wrap">
                            {[evCharger.voltageAcL1, evCharger.voltageAcL2, evCharger.voltageAcL3].map((voltage, index) => {
                                if (voltage === null || voltage < 50) {
                                    return null;
                                }
                                return (
                                    <div className="mr-3" key={ index }>
                                        <span className="text-muted">
                                            L{ index + 1 }:{" "}
                                        </span>
                                        <NumberFormatter number={ voltage }/> V
                                    </div>
                                );
                            })}
                        </div>
                    </Row>
                )}
                <Row title="Laatst gezien">
                    <DateFormatter date={ evCharger.lastSeenDate }/>
                </Row>
                { onClickAddDevice && evCharger.installationId === null && (
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ () => onClickAddDevice("evCharger", evCharger.id) }
                            disabled={ disabled }
                        >
                            <i className="fa-solid fa-plus mr-2"/>
                            Laadpaal toevoegen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

function InstallationEVChargerCards({
    evChargers,
    onClickEVChargerState,
    onClickAddDevice,
    disabled,
}) {
    return evChargers.map((evCharger) => (
        <InstallationEVChargerCard
            key={evCharger.id}
            evCharger={evCharger}
            onClickEVChargerState={ onClickEVChargerState }
            onClickAddDevice={onClickAddDevice}
            disabled={disabled}
        />
    ));
}

export default React.memo(InstallationEVChargerCards);
