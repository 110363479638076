import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Spinner
} from "react-bootstrap";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageColumnFooter,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

import Helmet from "../../components/Helmet";
import Copyright from "./Copyright";
import Logo from "../../components/Logo";
import Loading from "../../components/Loading";

import privacyPolicy from "../../assets/privacyverklaring-gridsense.pdf";

function RegisterInvite() {
    const match = useRouteMatch();
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [valid, setValid] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const token = match.params.token;
        if(!token) {
            setValid(false);
            setError("Ongeldige registratie link.");
            return;
        }
        const checkInvite = async () => {
            try {
                const response = await axios.post("/checkUserInstallationInvite", { token })
                setValid(true);
                setEmail(response.data.email);
            } catch(requestError) {
                console.error(requestError);
                setValid(false);
                if(requestError.response.data.error) {
                    switch(requestError.response.data.error) {
                        case "INVALID_TOKEN":
                            setError("Ongeldige registratie link.");
                            break;
                        case "TOKEN_USED":
                            setError("Deze registratie link is al gebruikt.");
                            break;
                        default:
                            setError("Ongeldige registratie link.");
                            break;
                    }
                } else {
                    setError("Ongeldige registratie link.");
                }
            }
        }
        checkInvite();
    }, [match]);

    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            if(!privacyPolicyChecked) {
                setError("Je moet akkoord gaan met de privacyverklaring.");
                return;
            }
            setLoading(true);
            axios.post("/registerUsingInvite", {
                token: match.params.token,
                name,
                password,
                passwordConfirm
            })
                .then((response) => {
                    if(response.data.valid) {
                        setSuccess("Account geregistreerd. Je kunt nu inloggen met je email en wachtwoord.");
                    } else {
                        setError(authenticatedUserContext.getErrorMessage(response.data.error));
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setError(authenticatedUserContext.getErrorMessage(error.response?.data?.error));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [match, authenticatedUserContext, name, password, passwordConfirm, privacyPolicyChecked]);

    return (
        <AuthenticationPage>
            <Helmet/>
            <AuthenticationPageColumn>
                <Logo className="login-logo"/>
                <h3 className="text-center text-muted mt-0">
                    Registreren
                </h3>
                { error && (
                    <React.Fragment>
                        <Alert variant="danger">
                            { error }
                        </Alert>
                    </React.Fragment>
                )}
                {valid === null ? (
                    <div>
                        <Loading/>
                    </div>
                ) : !valid ? (
                    <div className="text-center mb-4">
                        <Link to="/login" style={{fontSize: "0.9rem"}}>
                            <i className="fa-solid fa-chevron-left mr-2"/>
                            Naar inloggen
                        </Link>
                    </div>
                ) : success ? (
                    <React.Fragment>
                        <Alert variant="success">
                            { success }
                        </Alert>
                        <div className="text-center mb-4">
                            <Link to="/login" style={{ fontSize: "0.9rem" }}>
                                <i className="fa-solid fa-chevron-left mr-2"/>
                                Naar inloggen
                            </Link>
                        </div>
                    </React.Fragment>
                ) : (
                    <form onSubmit={ onSubmit }>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Naam"
                                disabled={ loading }
                                value={ name }
                                onChange={(event) => setName(event.target.value) }
                                autoComplete="name"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                disabled
                                value={ email }
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Wachtwoord"
                                disabled={ loading }
                                value={ password }
                                onChange={(event) => setPassword(event.target.value) }
                                autoComplete="new-password"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="passwordConfirm"
                                placeholder="Wachtwoord verificatie"
                                disabled={ loading }
                                value={ passwordConfirm }
                                onChange={(event) => setPasswordConfirm(event.target.value) }
                                autoComplete="new-password"
                            />
                        </div>
                        <div className="custom-control custom-checkbox mb-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="privacyPolicyCheckbox"
                                disabled={ loading }
                                onChange={(event) => setPrivacyPolicyChecked(event.target.checked) }
                                checked={ privacyPolicyChecked }
                            />
                            <label className="custom-control-label" htmlFor="privacyPolicyCheckbox">
                                Ik ga akkoord met de{" "}
                                <a href={ privacyPolicy } target="_blank" rel="noopener noreferrer">privacyverklaring</a>.
                            </label>
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={ loading }>
                                { loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Verstuur
                            </button>
                        </div>
                    </form>
                )}

                <AuthenticationPageColumnFooter>
                    <a href="https://smithsolar.nl">
                        Smith Solar website
                    </a>
                </AuthenticationPageColumnFooter>
            </AuthenticationPageColumn>

            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    )
}

export default React.memo(RegisterInvite);
