import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link,
    useHistory,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../components/Loading";

function PaymentProcessing({ match }) {
    const history = useHistory();
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!match) {
            return;
        }
        const {
            token
        } = match.params;
        axios.post("/setupDirectDebitFinish", {
            token
        })
            .then((response) => {
                if(response.data.valid) {
                    history.replace("/settings/payment");
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            });
    }, [match, history]);

    return (
        <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            { error ? (
                <div className="text-center">
                    <Alert variant="danger">{ error }</Alert>
                    <Link
                        to="/settings/payment"
                        className="btn btn-primary"
                    >
                        Terug naar instellingen
                    </Link>
                </div>
            ) : (
                <div className="text-center">
                    <h1>Betaling afronden...</h1>
                    <Loading/>
                </div>
            )}
        </div>
    )
}

export default React.memo(withRouter(PaymentProcessing));
