import React from "react";

function InfoCardRow({ title, children, className }) {
    return (
        <div className={ "mt-2" + (className ? " " + className : "") }>
            <h6 className="text-muted card-title mb-0">
                { title }
            </h6>
            <p className="mb-0">
                { children }
            </p>
        </div>
    )
}

export default React.memo(InfoCardRow);
