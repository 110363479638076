import React, {
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Spinner
} from "react-bootstrap";

function SettingsPassword() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = useMemo(() => {
        return async (event) => {
            event.preventDefault();
            setError(null);
            setLoading(true);
            try {
                await axios.post("/setPassword", {
                    currentPassword,
                    password,
                    passwordConfirm
                });
                setSuccess(true);
                setCurrentPassword("");
                setPassword("");
                setPasswordConfirm("");
            } catch(error) {
                console.error(error);
                if(error.response && error.response.data && error.response.data.error) {
                    switch(error.response.data.error) {
                        case "SESSON_EXPIRED":
                            setError("Je sessie is verlopen. Log opnieuw in.");
                            break;
                        case "INVALID_PASSWORD":
                            setError("Huidige wachtwoord is onjuist.");
                            break;
                        case "PASSWORD_MISMATCH":
                            setError("Wachtwoorden komen niet overeen.");
                            break;
                        case "PASSWORD_INSECURE":
                            setError("Wachtwoord is niet veilig genoeg.");
                            break;
                        default:
                            setError("Er is een fout opgetreden bij het opslaan van het wachtwoord. (" + error.response.data.error + ")");
                            break;
                    }
                } else {
                    setError("Er is een fout opgetreden bij het opslaan van het wachtwoord.");
                }
            } finally {
                setLoading(false);
            }
        }
    }, [currentPassword, password, passwordConfirm]);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success && (
                <Alert variant="success">Wachtwoord is gewijzigd.</Alert>
            )}
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Wachtwoord instellen</h5>
                    <p className="card-text">
                        Als je je wachtwoord veranderd zul je op alle apparaten opnieuw moeten inloggen.
                    </p>
                    <form onSubmit={ handleSubmit }>
                        <div className="form-group">
                            <label htmlFor="currentPassword">Huidige wachtwoord</label>
                            <input
                                type="password"
                                className="form-control"
                                id="currentPassword"
                                value={ currentPassword }
                                onChange={ (event) => { setCurrentPassword(event.target.value) }}
                                autoComplete="current-password"
                                disabled={ loading }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Nieuwe wachtwoord</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                value={ password }
                                onChange={ (event) => { setPassword(event.target.value) }}
                                autoComplete="new-password"
                                disabled={ loading }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="passwordConfirm">Wachtwoord bevestigen</label>
                            <input
                                type="password"
                                className="form-control"
                                id="passwordConfirm"
                                value={ passwordConfirm }
                                onChange={ (event) => { setPasswordConfirm(event.target.value) }}
                                autoComplete="new-password"
                                disabled={ loading }
                            />
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={ loading }
                            >
                                { loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Opslaan
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(SettingsPassword);
