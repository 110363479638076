import React, {
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    friendlyFormatIBAN
} from "ibantools";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";

import chip from "../img/bank-card/chip.png";

function BankAccountCard({ name, iban }) {
    const cardRef = useRef();
    const [dimensions, setDimensions] = useState({ width:0, height: 0 });
    const windowSize = useWindowSize();

    useLayoutEffect(() => {
        if(!cardRef.current) {
            return;
        }
        setDimensions({
            width: cardRef.current.offsetWidth,
            height: cardRef.current.offsetHeight
        });
    }, [windowSize]);

    const ibanSplit = useMemo(() => {
        return friendlyFormatIBAN(iban).split(" ");
    }, [iban]);

    return (
        <div className="bank-card" ref={ cardRef }>
            <div className="card-inner">
                <div className="front">
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="chip-container">
                                <img src={ chip } className="chip" alt="Chip"/>
                            </div>
                        </div>
                        <div
                            className="card-no d-flex flex-row justify-content-between"
                            style={{ fontSize: (dimensions.width / 16) + "px" }}
                        >
                            { ibanSplit.map((part, index) => (
                                <span key={ index }>{ part }</span>
                            ))}
                        </div>
                        <div>
                            <div
                                className="card-holder"
                                style={{ fontSize: (dimensions.width / 23) + "px" }}
                            >
                                <small>NAAM</small>
                            </div>
                            <div
                                className="name"
                                style={{ fontSize: (dimensions.width / 20) + "px" }}
                            >
                                <span>{ name }</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(BankAccountCard);
