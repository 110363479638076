import React from "react";

import ChartProductionForecast from "../../../../../charts/ChartProductionForecast";
import ChartCard from "../../../../../components/charts/ChartCard";

function InstallationProductionForecastChartCard({ installation, startDate, endDate }) {
    if(installation?.userRole?.value !== "admin") {
        return null;
    }
    return (
        <ChartCard
            title="Verwachte productie (kWh)"
            height={ 300 }
        >
            <ChartProductionForecast
                startDate={ startDate }
                endDate={ endDate }
                installationId={ installation?.id }
            />
        </ChartCard>
    )
}

export default React.memo(InstallationProductionForecastChartCard);
