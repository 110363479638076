import React, {
    useMemo
} from "react";

function RadioChip({ checked, onClick, className, children }) {
    const classNames = useMemo(() => {
        let classNameArray = ["rounded-pill", "btn", "btn-sm", "px-3"];
        if(checked) {
            classNameArray.push("btn-primary");
        } else {
            classNameArray.push("btn-outline-secondary");
        }
        if(className) {
            classNameArray.push(className);
        }
        return classNameArray;
    }, [className, checked]);
    return (
        <button className={ classNames.join(" ") } onClick={ onClick }>
            { children }
        </button>
    )
}

export default React.memo(RadioChip);
