import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import {
    useHistory
} from "react-router-dom";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import ChartCard from "../../components/charts/ChartCard";
import ChartEnergyPrices from "../../charts/ChartEnergyPrices";
import ChartDateSwitcher from "./components/ChartDateSwitcher";

function EnergyPrices({ match }) {
    const [date, setDate] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if(!match.params.date) {
            setDate(moment());
        }
        setDate(moment(match.params.date));
    }, [match]);
    const dateRange = useMemo(() => {
        if(!date) {
            return null;
        }
        return {
            startDate: date.clone().startOf("day"),
            endDate: date.clone().endOf("day")
        }
    }, [date]);

    const onDateChange = useCallback((newDate) => {
        history.replace("/energy-prices/" + newDate.format("YYYY-MM-DD"));
    }, [history]);

    if(!dateRange) {
        return null;
    }
    return (
        <React.Fragment>
            <Helmet title="Energieprijs"/>
            <Title preTitle="Overzicht">
                Energieprijs
            </Title>
            <ChartCard
                cardTitle={
                    <ChartDateSwitcher
                        date={ date }
                        setDate={ onDateChange }
                    />
                }
                title={
                    <ChartDateSwitcher
                        date={ date }
                        setDate={ onDateChange }
                        tooltipPlacement="bottom"
                    />
                }
            >
                <ChartEnergyPrices { ...dateRange }/>
            </ChartCard>
        </React.Fragment>
    );
}

export default React.memo(EnergyPrices);
