import React from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";

function BottomTabBarLink({to, title, icon, exact = false, hidden = false, onClick}) {
    let match = useRouteMatch({
        path: to,
        exact: exact
    });
    let className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className }>
            <Link to={ to } className="nav-link bottom-tabbar-link" onClick={ onClick }>
                { icon && (
                    <i className={ "bottom-tabbar-icon " + icon }></i>
                )}
                <span>
                    { title }
                </span>
            </Link>
        </li>
    );
}

export default React.memo(BottomTabBarLink);
