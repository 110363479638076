import React from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

function EnergyPriceCostsExplanationModal({ show, handleClose }) {
    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Uitleg dynamische energieprijs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="modal-text">
                    De ruwe dynamische energieprijs is de prijs die je betaalt voor de energie die je verbruikt. Deze
                    prijs is afhankelijk van de markt en kan dus per uur verschillen. De prijzen die wij tonen zijn
                    exclusief btw.
                </p>
                <p className="modal-text">
                    Echter moeten er nog extra kosten bovenop deze ruwe energieprijs worden gerekend. Deze kosten zijn
                    de inkoopkosten en de energiebelasting. De inkoopkosten zijn de kosten die uw energieleverancier
                    rekent voor het leveren van de energie. De energiebelasting is een belasting die de overheid heft
                    op het verbruik van energie. Beide de inkoopkosten en de energiebelasting zijn een tarief per kWh,
                    exclusief btw.
                </p>
                <h5>
                    Voorbeeld
                </h5>
                <p className="modal-text">
                    In onderstaand rekenvoorbeeld gaan we uit van de volgende tarieven:
                </p>
                <ul>
                    <li>
                        Ruwe energieprijs: € 0,057 / kWh
                    </li>
                    <li>
                        Inkoopkosten: € 0,0175 / kWh
                    </li>
                    <li>
                        Energiebelasting: € 0,12599 / kWh
                    </li>
                </ul>
                <div className="d-flex flex-row justify-content-center w-100">
                    <div className="equation stacked" style={{ fontSize: "1.25rem" }}>
                        <span className="number">0,05700</span>
                        <span className="number">0,01750</span>
                        <span className="operator">+</span>
                        <span className="number">0,12599</span>
                        <span className="equals">=</span>
                        <span className="number">0,20049</span>
                    </div>
                </div>
                <p className="modal-text">
                    De prijs die u in dat uur betaalt is dus € 0,20049 / kWh, exclusief btw.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(EnergyPriceCostsExplanationModal);
