import React from "react";
import {
    Switch
} from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import useTracking from "./hooks/useTracking";

import Login from "./pages/login/Login";
import ForgotPassword from "./pages/login/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword";
import RegisterInvite from "./pages/login/RegisterInvite";
import PaymentProcessing from "./pages/payment-processing/PaymentProcessing";
import AppRouter from "./AppRouter";

function RootRouter({ authenticated }) {
    useTracking();
    return (
        <Switch>

            <PrivateRoute authenticated={ !authenticated }
                          path="/login" target="/dashboard"
                          component={Login}
            />

            <PrivateRoute authenticated={ !authenticated }
                          path="/forgot-password" target="/dashboard"
                          component={ForgotPassword}
            />

            <PrivateRoute authenticated={ !authenticated }
                          path="/reset-password/:token" target="/dashboard"
                          component={ResetPassword}
            />

            <PrivateRoute authenticated={ !authenticated }
                          path="/register-invite/:token" target="/dashboard"
                          component={RegisterInvite}
            />

            <PrivateRoute authenticated={ authenticated }
                          path="/payment-processing/:token" target="/login"
                          component={PaymentProcessing}
            />

            <PrivateRoute authenticated={ authenticated }
                          path="/" target="/login"
                          component={AppRouter}
            />

        </Switch>
    )
}

export default React.memo(RootRouter);
