import {
    useContext,
    useMemo
} from "react";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";

function testPermission(user, testFunction) {
    if(!user) {
        return null;
    }
    if(user.companies.length === 0) {
        return false;
    }
    const hasPermission = user.companies.find((company) => {
        return testFunction(company.role.permissions);
    });
    return hasPermission !== undefined;
}

function usePermissions() {
    const authentication = useContext(AuthenticatedUserContext);

    const manageCompanyUsers = useMemo(() => {
        return testPermission(authentication.user, (permissions) => {
            return permissions.manageCompanyUsers;
        });
    }, [authentication.user]);
    const manageInstallations = useMemo(() => {
        return testPermission(authentication.user, (permissions) => {
            return permissions.manageInstallations;
        });
    }, [authentication.user]);
    const globalAdmin = useMemo(() => {
        return authentication.user.globalAdmin;
    }, [authentication.user]);

    return {
        manageCompanyUsers,
        manageInstallations,
        globalAdmin,
    }
}

export default usePermissions;
