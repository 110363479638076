import React from "react";

function GatewaySettingsForm({ settings, values, setValue, disabled }) {
    return settings.map((setting) => {
        switch(setting.type) {
            case "select":
                return (
                    <div className="form-group" key={setting.key}>
                        <label htmlFor={setting.key}>{setting.displayName}</label>
                        <select
                            className="form-control"
                            id={ setting.key }
                            value={ values[setting.key] }
                            onChange={ (event) => setValue(setting.key, event.target.value) }
                            disabled={ disabled }
                        >
                            { setting.selectOptions.map(({ displayName, value }) => (
                                <option key={ value } value={ value }>
                                    { displayName }
                                </option>
                            ))}
                        </select>
                    </div>
                );
            default:
                return (
                    <div className="form-group" key={setting.key}>
                        <label htmlFor={setting.key}>{setting.displayName}</label>
                        <input
                            className="form-control"
                            type="text"
                            id={setting.key}
                            value={values[setting.key]}
                            disabled
                        />
                    </div>
                );
        }
    });
}

export default React.memo(GatewaySettingsForm);
