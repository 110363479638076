import React from "react";

function BottomTabBar({ children, className, ...props }) {
    return (
        <nav
            className={ "navbar navbar-expand-lg navbar-light bg-light fixed-bottom bottom-tabbar" + (className ? ` ${className}` : "") }
            { ...props }
        >
            <div className="container">
                <ul className="navbar-nav flex-row justify-content-around w-100">
                    { children }
                </ul>
            </div>
        </nav>
    )
}

export default React.memo(BottomTabBar);
