import React, {
    useMemo
} from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function getIcon(type) {
    switch(type) {
        case "private":
            return "fa-solid fa-house";
        case "commercial":
            return "fa-solid fa-industry";
        default:
            return "fa-solid fa-question";
    }
}

function getName(type) {
    switch(type) {
        case "private":
            return "Particulier";
        case "commercial":
            return "Commercieël";
        default:
            return "Onbekend";
    }
}

function InstallationTypeIcon({ type, className }) {
    const icon = useMemo(() => {
        return getIcon(type);
    }, [type]);
    const name = useMemo(() => {
        return getName(type);
    }, [type]);
    const classNames = useMemo(() => {
        let classNamesArray = [icon];
        if(className) {
            classNamesArray.push(className);
        }
        return classNamesArray.join(" ");
    }, [className, icon]);
    return (
        <OverlayTrigger overlay={
            <Tooltip id={`tooltip-installation-type`}>
                { name }
            </Tooltip>
        }>
            <i className={ classNames }/>
        </OverlayTrigger>
    )
}

export default React.memo(InstallationTypeIcon);
