import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../components/Loading";
import InfoCardRow from "../../components/InfoCardRow";
import EuroFormatter from "../../components/formatters/EuroFormatter";
import DateFormatter from "../../components/formatters/DateFormatter";

function SettingsSubscriptions() {
    const [subscriptions, setSubscriptions] = useState(null);
    const [error, setError] = useState(null);

    const refreshSubscriptions = useCallback(async () => {
        setSubscriptions(null);
        setError(null);
        try {
            const response = await axios.get("/getSubscriptions");
            setSubscriptions(response.data.subscriptions);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden bij het ophalen van de gegevens.");
        }
    }, []);

    useEffect(() => {
        refreshSubscriptions();
    }, [refreshSubscriptions]);

    const columnClassName = "col-md-6 col-lg-4 col-xl-3";
    return (
        <React.Fragment>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !subscriptions ? (
                <Loading/>
            ) : subscriptions.length === 0 ? (
                <div className="text-center">
                    <h1><i className="fa-solid fa-credit-card"/></h1>
                    <h4>Geen abonnementen</h4>
                    <p>Er zijn nog geen abonnementen geactiveerd op jou account.</p>
                </div>
            ) : subscriptions.map((subscription) => (
                <div className="card mb-3" key={ subscription.id }>
                    <div className="card-body">
                        <h5 className="card-title">{ subscription.name }</h5>
                        <div className="row">
                            <div className={ columnClassName }>
                                <InfoCardRow title="Startdatum">
                                    <DateFormatter date={ subscription.startDate } format="DD-MM-YYYY"/>
                                </InfoCardRow>
                            </div>
                            <div className={ columnClassName }>
                                <InfoCardRow title="Periode">
                                    { subscription.intervalMonths === 1 ? (
                                        "Maandelijks"
                                    ) : subscription.intervalMonths === 3 ? (
                                        "Elk kwartaal"
                                    ) : subscription.intervalMonths === 6 ? (
                                        "Elk half jaar"
                                    ) : subscription.intervalMonths === 12 ? (
                                        "Jaarlijks"
                                    ) : (
                                        `Elke ${subscription.intervalMonths} maanden`
                                    )}
                                </InfoCardRow>
                            </div>
                            <div className={ columnClassName }>
                                <InfoCardRow title="Prijs per periode">
                                    <EuroFormatter price={ subscription.intervalPrice }/>
                                    <small className="text-muted">
                                        {" "}
                                        (<EuroFormatter price={ subscription.intervalPrice / subscription.intervalMonths }/> / mnd)
                                    </small>
                                </InfoCardRow>
                            </div>
                            <div className={ columnClassName }>
                                <InfoCardRow title="Installatie">
                                    { subscription.installation ? (
                                        <Link to={`/installation/${subscription.installation.id}`}>
                                            { subscription.installation.name }
                                        </Link>
                                    ) : (
                                        "Onbekend"
                                    )}
                                </InfoCardRow>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
}

export default React.memo(SettingsSubscriptions);
