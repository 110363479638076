import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import moment from "moment-timezone";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";
import {
    ErrorBoundary
} from "@sentry/react";

import ChartLoading from "../components/charts/ChartLoading";
import ChartNoData from "../components/charts/ChartNoData";
import NumberFormatter from "../components/formatters/NumberFormatter";

function ChartEnergyPrices({ startDate, endDate, installationId }) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const currentHourString = useMemo(() => {
        return moment().format("YYYY-MM-DD HH");
    }, []);

    useEffect(() => {
        if(!startDate || !endDate) {
            return;
        }
        const startDateFormat = startDate.tz("Europe/Amsterdam").toISOString();
        const endDateFormat = endDate.tz("Europe/Amsterdam").toISOString();
        setData(null);
        setError(null);
        let parameters = {
            startDate: startDateFormat,
            endDate: endDateFormat
        }
        if(installationId) {
            parameters.installationId = installationId;
        }
        const url = installationId ? "/getEnergyPricesForInstallation" : "/getEnergyPrices";
        axios.post(url, parameters)
            .then((response) => {
                if(response.data.valid) {
                    if(!response.data.prices || response.data.prices.length === 0) {
                        setData([]);
                        return;
                    }
                    setData([{
                        label: "Energieprijzen",
                        data: response.data.prices.map((priceDatum) => ({
                            key: moment(priceDatum.date).format("YYYY-MM-DD HH:mm:ss"),
                            value: priceDatum.price
                        }))
                    }]);
                } else {
                    setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            });
    }, [startDate, endDate, installationId]);
    const minimumValue = useMemo(() => {
        if(!data) {
            return 0;
        }
        let workingValue = 0;
        data.forEach((dataSet) => {
            dataSet.data.forEach((dataPoint) => {
                let comparedValue = dataPoint.value;
                if(dataPoint.value < 0) {
                    comparedValue -= 0.02;
                }
                workingValue = Math.min(workingValue, comparedValue);
            });
        });
        return workingValue;
    }, [data]);
    const maximumValue = useMemo(() => {
        if(!data) {
            return 0;
        }
        let workingValue = 0.10;
        data.forEach((dataSet) => {
            dataSet.data.forEach((dataPoint) => {
                let comparedValue = dataPoint.value;
                if(dataPoint.value < 0) {
                    comparedValue += 0.02;
                }
                workingValue = Math.max(workingValue, comparedValue);
            });
        });
        return Math.ceil(workingValue * 100) / 100;
    }, [data]);

    const primaryAxis = useMemo(() => ({
        getValue: (datum) => moment(datum.key).format("HH:mm")
    }), []);
    const secondaryAxes = useMemo(() => [{
        getValue: (datum) => {
            return datum.value;
        },
        formatters: {
            scale: (value) => (
                <NumberFormatter
                    number={ value }
                    minimumFractionDigits={ 2 }
                />
            ),
            tooltip: (value) => (
                <NumberFormatter
                    number={ value }
                    minimumFractionDigits={ 3 }
                />
            )
        },
        min: minimumValue,
        max: maximumValue
    }], [minimumValue, maximumValue]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <ErrorBoundary fallback={
            <Alert variant="danger">Er ging iets fout. Probeer het later opnieuw.</Alert>
        }>
            <Chart
                options={{
                    data,
                    primaryAxis,
                    secondaryAxes,
                    getDatumStyle: (datum) => {
                        if(moment(datum.originalDatum.key).format("YYYY-MM-DD HH") !== currentHourString) {
                            return {}
                        }
                        return {
                            color: "#40bfff"
                        }
                    }
                }}
            />
        </ErrorBoundary>
    )
}

export default React.memo(ChartEnergyPrices);
