import React from "react";
import ImageUploading from "react-images-uploading";

function ImageUploadArea({
                             images,
                             onChange,
                             loading = false,
                             multiple = false,
                             emptyState = "Klik hier om een foto toe te voegen",
}) {
    return (
        <ImageUploading
            multiple={ multiple }
            value={ images }
            onChange={ onChange }
            maxNumber={ 20 }
            dataURLKey="data_url"
            acceptType={ ["jpg", "png", "jpeg"] }
        >
            {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
              }) => (
                <div className="image-upload">
                    <button
                        className={ "btn btn-image-upload" + (isDragging ? " btn-image-upload-dragging" : "") }
                        onClick={ onImageUpload }
                        disabled={ loading }
                        { ...dragProps }
                    >
                        { emptyState }
                    </button>
                    { imageList.length > 0 && (
                        <div className="mt-2">
                            <div className="clearfix">
                                <button className="btn btn-secondary btn-sm float-right" onClick={ onImageRemoveAll } disabled={ loading }>
                                    { multiple ? "Verwijder alle" : "Verwijder" }
                                </button>
                            </div>
                            <div className="d-flex flex-wrap mt-2">
                                { imageList.map((image, index) => (
                                    <div key={ index } className="image-item">
                                        <button className="btn btn-sm btn-remove" onClick={ () => onImageRemove(index) } disabled={ loading }>
                                            <i className="fas fa-fw fa-times"/>
                                        </button>
                                        <button className="btn btn-image" onClick={ () => onImageUpdate(index) } disabled={ loading }>
                                            <img src={ image.data_url } alt="" width="100" />
                                        </button>
                                    </div>
                                )) }
                            </div>
                        </div>
                    )}
                </div>
            )}
        </ImageUploading>
    )
}

export default React.memo(ImageUploadArea);
