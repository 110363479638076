import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";
import EnergyHandlingChart from "../EnergyHandlingChart";

function InstallationEnergyHandlingChart({ installation, startDate, endDate }) {
    const [modesPerInverter, setModesPerInverter] = useState(null);
    const [error, setError] = useState(null);
    const [unavailable, setUnavailable] = useState(false);

    useEffect(() => {
        if(!startDate || !endDate || !installation) {
            return;
        }
        const startDateFormat = startDate.tz("Europe/Amsterdam").toISOString();
        const endDateFormat = endDate.tz("Europe/Amsterdam").toISOString();
        setModesPerInverter(null);
        setError(null);
        setUnavailable(false);
        axios.post("/getEnergyHandlingForInstallation", {
            installationId: installation.id,
            startDate: startDateFormat,
            endDate: endDateFormat
        })
            .then((response) => {
                if(response.data.valid) {
                    setModesPerInverter(response.data.modesPerInverter);
                    setUnavailable(!response.data.modesPerInverter || response.data.modesPerInverter.length === 0);
                } else {
                    setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            });
    }, [installation, startDate, endDate]);

    const allNull = useMemo(() => {
        if(!modesPerInverter) {
            return true;
        }
        return modesPerInverter.some((modesForInverter) => {
            return !modesForInverter?.modes;
        });
    }, [modesPerInverter]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">Sturing</h5>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !modesPerInverter ? (
                    <Loading/>
                ) : allNull ? (
                    <p className="mb-0">
                        Geen sturing beschikbaar.
                    </p>
                ) : modesPerInverter.map((modesForInverter, index) => {
                    if(!modesForInverter?.modes) {
                        return null;
                    }
                    return (
                        <EnergyHandlingChart
                            key={ index }
                            hideLabels={ index !== modesPerInverter.length - 1 }
                            modes={ modesForInverter.modes }
                            unavailable={ unavailable }
                        />
                    );
                })}
            </div>
        </div>
    )
}

export default React.memo(InstallationEnergyHandlingChart);
