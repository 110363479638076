import React from "react";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";

import SettingsPassword from "./SettingsPassword";
import SettingsPayment from "./SettingsPayment";
import SettingsSubscriptions from "./SettingsSubscriptions";
import SettingsInvoices from "./SettingsInvoices";

function Settings() {
    const baseUrl = "/settings";
    return (
        <React.Fragment>
            <Helmet title="Instellingen"/>
            <Title preTitle="Overzicht" noBottom>
                Instellingen
            </Title>
            <TabBar>
                <TabBarItem to={ `${baseUrl}/password` }>
                    Wachtwoord
                </TabBarItem>
                <TabBarItem to={ `${baseUrl}/payment` }>
                    Betaling
                </TabBarItem>
                <TabBarItem to={ `${baseUrl}/subscriptions` }>
                    Abonnementen
                </TabBarItem>
                <TabBarItem to={ `${baseUrl}/invoices` }>
                    Facturen
                </TabBarItem>
            </TabBar>
            <Switch>
                <Route path={ `${baseUrl}/password` } exact>
                    <SettingsPassword/>
                </Route>
                <Route path={ `${baseUrl}/payment` } exact>
                    <SettingsPayment/>
                </Route>
                <Route path={ `${baseUrl}/subscriptions` } exact>
                    <SettingsSubscriptions/>
                </Route>
                <Route path={ `${baseUrl}/invoices` } exact>
                    <SettingsInvoices/>
                </Route>
                <Route path={ baseUrl }>
                    <Redirect to={ `${baseUrl}/password` }/>
                </Route>
            </Switch>
        </React.Fragment>
    );
}

export default React.memo(Settings);
