import React, {
    useCallback,
    useMemo
} from "react";
import moment from "moment";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function ChartDateSwitcher({ date, setDate, tooltipPlacement = "top" }) {
    const prevDate = useMemo(() => {
        return date.clone().subtract(1, "day");
    }, [date]);
    const nextDate = useMemo(() => {
        return date.clone().add(1, "day");
    }, [date]);

    const setDateNext = useCallback(() => {
        setDate(nextDate);
    }, [setDate, nextDate]);
    const setDateToday = useCallback(() => {
        setDate(moment());
    }, [setDate]);
    const setDatePrev = useCallback(() => {
        setDate(prevDate);
    }, [setDate, prevDate]);

    return (
        <div className="d-flex flex-row align-items-center w-100">
            <div>
                <OverlayTrigger placement={ tooltipPlacement } overlay={
                    <Tooltip id={`tooltip-chart-previous`}>
                        Vorige dag
                    </Tooltip>
                }>
                    <Button
                        variant="link"
                        className="px-0"
                        onClick={ setDatePrev }
                    >
                        <i className="fa-solid fa-angle-left mr-md-2"/>
                        <span className="d-none d-md-inline-block">
                            { prevDate.format("DD-MM-YYYY") }
                        </span>
                    </Button>
                </OverlayTrigger>
            </div>
            <div className="flex-grow-1 d-flex justify-content-center text-center" style={{ fontWeight: 500 }}>
                <OverlayTrigger placement={ tooltipPlacement } overlay={
                    <Tooltip id={`tooltip-chart-today`}>
                        Naar vandaag
                    </Tooltip>
                }>
                    <Button
                        variant="today-link"
                        className="px-0"
                        onClick={ setDateToday }
                    >
                        <div className="d-none d-lg-block">
                            { date.format("dddd DD-MM-YYYY") }
                        </div>
                        <div className="d-lg-none">
                            { date.format("dddd") }
                            <br/>
                            { date.format("DD-MM-YYYY") }
                        </div>
                    </Button>
                </OverlayTrigger>
            </div>
            <div>
                <OverlayTrigger placement={ tooltipPlacement } overlay={
                    <Tooltip id={`tooltip-chart-next`}>
                        Volgende dag
                    </Tooltip>
                }>
                    <Button
                        variant="link"
                        className="px-0"
                        onClick={ setDateNext }
                    >
                        <span className="d-none d-md-inline-block">
                            { nextDate.format("DD-MM-YYYY") }
                        </span>
                        <i className="fa-solid fa-angle-right ml-md-2"/>
                    </Button>
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default React.memo(ChartDateSwitcher);
