import React, {
    useMemo
} from "react";

function BatteryIcon({ soe, className, ...props }) {
    const selection = useMemo(() => {
        if(soe === undefined || soe === null) {
            return null;
        }
        const percentage = Math.max(Math.min(soe, 100), 0);
        return Math.round(percentage / 100 * 4);
    }, [soe]);

    const icon = useMemo(() => {
        switch(selection) {
            case 0: return "fa-battery-empty";
            case 1: return "fa-battery-quarter";
            case 2: return "fa-battery-half";
            case 3: return "fa-battery-three-quarters";
            case 4: return "fa-battery-full";
            default: return "fa-battery-slash";
        }
    }, [selection]);

    return (
        <i
            className={"fas " + icon + " " + (className ?? "")}
            { ...props }
        />
    );
}

export default React.memo(BatteryIcon);
