import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Link,
    useParams
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../../components/Helmet";
import Loading from "../../../components/Loading";
import {
    InstallationInverterCard
} from "../../installations/detail/components/InstallationInverterCards";
import {
    InstallationBatteryCard
} from "../../installations/detail/components/InstallationBatteryCards";
import {
    InstallationEnergyMeterCard
} from "../../installations/detail/components/InstallationEnergyMeterCards";

function GatewayDetail() {
    const {
        uuid
    } = useParams();
    const [gateway, setGateway] = useState(null);
    const [error, setError] = useState(null);

    const retrieveGateway = useCallback(async () => {
        setGateway(null);
        setError(null);
        try {
            const response = await axios.post("/getGateway", {
                gatewayUUID: uuid
            });
            setGateway(response.data.gateway);
        } catch (error) {
            console.error(error);
            setError("Er ging iets fout.");
        }
    }, [uuid]);
    useEffect(() => {
        retrieveGateway();
    }, [retrieveGateway]);

    const totalDeviceCount = useMemo(() => {
        if(!gateway) {
            return 0;
        }
        return [
            ...(gateway.inverters || []),
            ...(gateway.batteries || []),
            ...(gateway.energyMeters || []),
        ].length;
    }, [gateway]);

    const {
        inverters,
        batteries,
        energyMeters
    } = gateway || {};

    return (
        <React.Fragment>
            <Helmet title="Gateway"/>
            <Title preTitle="Gateway">
                { gateway ? gateway.uuid : "Gateway" }
            </Title>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !gateway ? (
                <Loading/>
            ) : (
                <React.Fragment>
                    <div className="card mb-3">
                        <div className="card-body">
                            { gateway.installation ? (
                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                    <div className="flex-grow-1">
                                        Deze gateway is toegewezen aan installatie{" "}
                                        <b>
                                            { gateway.installation.name }
                                        </b>.
                                    </div>
                                    <div className="d-flex justify-content-end mt-2 mt-md-0 ml-2">
                                        <Link className="btn btn-secondary btn-sm" to={`/installation/${gateway.installation.id}`}>
                                            Naar installatie
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <p className="card-text">
                                    Deze gateway is niet toegewezen aan een installatie.
                                </p>
                            )}
                        </div>
                    </div>

                    { totalDeviceCount === 0 && (
                        <div className="card mb-3">
                            <div className="card-body text-center">
                                <h1 className="mt-0">
                                    <i className="fa-solid fa-solar-panel"/>
                                </h1>
                                <h4>Geen apparaten gevonden</h4>
                                <p className="card-text">
                                    Er zijn nog geen apparaten gedetecteerd door deze gateway.
                                </p>
                            </div>
                        </div>
                    )}
                    { inverters && inverters.length > 0 && (
                        <React.Fragment>
                            <h4>Omvormers</h4>
                            <div className="row">
                                { inverters.map((inverter) => (
                                    <div className="col-12 col-lg-6 col-xxl-4" key={ inverter.id }>
                                        <InstallationInverterCard
                                            inverter={ inverter }
                                        />
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                    { batteries && batteries.length > 0 && (
                        <React.Fragment>
                            <h4>Batterijen</h4>
                            <div className="row">
                                { batteries.map((battery) => (
                                    <div className="col-12 col-lg-6 col-xxl-4" key={ battery.id }>
                                        <InstallationBatteryCard
                                            battery={ battery }
                                        />
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                    { energyMeters && energyMeters.length > 0 && (
                        <React.Fragment>
                            <h4>Energiemeters</h4>
                            <div className="row">
                                { energyMeters.map((energyMeter) => (
                                    <div className="col-12 col-lg-6 col-xxl-4" key={ energyMeter.id }>
                                        <InstallationEnergyMeterCard
                                            energyMeter={ energyMeter }
                                        />
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(GatewayDetail);
