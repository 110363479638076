import React from "react";
import {
    SidebarManager
} from "@zandor300/backoffice-framework";

function AppManager({ children }) {
    return (
        <SidebarManager>
            { children }
        </SidebarManager>
    )
}

export default AppManager;
