import React from "react";
import moment from "moment";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import usePermissions from "../../hooks/usePermissions";
import ChartCard from "../../components/charts/ChartCard";
import ChartEnergyPrices from "../../charts/ChartEnergyPrices";
import DashboardTotalEnergyFlowCard from "./components/DashboardTotalEnergyFlowCard";

function Dashboard() {
    const permissions = usePermissions();
    const now = moment().tz("Europe/Amsterdam", true);
    return (
        <React.Fragment>
            <Helmet title="Dashboard"/>
            <Title preTitle="Overzicht">
                Dashboard
            </Title>
            <div className="row">
                <div className="col-lg-6">
                    <ChartCard title="Dynamische energieprijs vandaag (excl. btw)">
                        <ChartEnergyPrices
                            startDate={ now.clone().startOf("day") }
                            endDate={ now.clone().endOf("day") }
                        />
                    </ChartCard>
                </div>
                <div className="col-lg-6">
                    <ChartCard title="Dynamische energieprijs morgen (excl. btw)">
                        <ChartEnergyPrices
                            startDate={ now.clone().startOf("day").add(1, "day") }
                            endDate={ now.clone().endOf("day").add(1, "day") }
                        />
                    </ChartCard>
                </div>
                { permissions.globalAdmin && (
                    <div className="col-lg-6">
                        <DashboardTotalEnergyFlowCard/>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default React.memo(Dashboard);
