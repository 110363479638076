import React from "react";

import RadioChip from "../../../../components/RadioChip";

function InstallationForm({ settings, setSettings, saving }) {
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Algemene instellingen</h5>
                    <div className="form-group">
                        <label htmlFor="name">Naam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={ settings.name }
                            onChange={ (event) => setSettings({ ...settings, name: event.target.value }) }
                            disabled={ saving }
                        />
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="type">Installatie type</label>
                        <div className="d-flex flex-row">
                            <RadioChip
                                checked={ settings.type === "private" }
                                onClick={ () => setSettings({ ...settings, type: "private" }) }
                                className="mr-2"
                            >
                                <i className="fa-solid fa-house mr-2"/>
                                Particulier
                            </RadioChip>
                            <RadioChip
                                checked={ settings.type === "commercial" }
                                onClick={ () => setSettings({ ...settings, type: "commercial" }) }
                                className="mr-2"
                            >
                                <i className="fa-solid fa-industry mr-2"/>
                                Commercieel
                            </RadioChip>
                        </div>
                        <small className="form-text text-muted">
                            Deze instelling heeft betrekking tot de geheven energiebelasting.
                        </small>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Adres</h5>
                    <div className="form-group">
                        <label htmlFor="address">Straat en huisnummer</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={ settings.address }
                            onChange={ (event) => setSettings({ ...settings, address: event.target.value }) }
                            disabled={ saving }
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="postalCode">Postcode</label>
                        <input
                            type="text"
                            className="form-control"
                            id="postalCode"
                            value={ settings.postalCode }
                            onChange={ (event) => setSettings({ ...settings, postalCode: event.target.value }) }
                            disabled={ saving }
                        />
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="city">Plaats</label>
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            value={ settings.city }
                            onChange={ (event) => setSettings({ ...settings, city: event.target.value }) }
                            disabled={ saving }
                        />
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Energieprijs</h5>
                    <div className="form-group mb-0">
                        <label htmlFor="energyPurchasingCost">Inkoopkosten</label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="energyPurchasingCost"
                                value={ settings.energyPurchasingCost }
                                onChange={ (event) => setSettings({ ...settings, energyPurchasingCost: event.target.value }) }
                                disabled={ saving }
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">EUR / kWh</span>
                            </div>
                        </div>
                        <small className="form-text text-muted">
                            De inkoopkosten zijn de kosten die je energieleverancier in rekening brengt voor het leveren van energie.
                        </small>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Omvormer sturing</h5>
                    <div className="form-group">
                        <label htmlFor="minEnergyPriceDeltaForChargeCycle">
                            Minimale energieprijs verschil voor slimme batterij laadcyclus
                        </label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">≥</span>
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                id="minEnergyPriceDeltaForChargeCycle"
                                value={ settings.minEnergyPriceDeltaForChargeCycle ?? "" }
                                onChange={ (event) => setSettings({ ...settings, minEnergyPriceDeltaForChargeCycle: event.target.value }) }
                                disabled={ saving }
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">EUR</span>
                            </div>
                        </div>
                        <small className="form-text text-muted">
                            Hoeveel verschil moet er in de dal en piekprijs op een dag zitten om een slimme batterij laadcyclus te starten.
                            <br/>
                            Aanbevolen: &euro; 0,05
                            <br/>
                            Minimaal: &euro; 0,03
                        </small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="lowEnergyPriceThresholdDisableInverter">Omvormer afschakelprijs</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">≤</span>
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                id="lowEnergyPriceThresholdDisableInverter"
                                value={ settings.lowEnergyPriceThresholdDisableInverter ?? "" }
                                onChange={ (event) => setSettings({ ...settings, lowEnergyPriceThresholdDisableInverter: event.target.value }) }
                                disabled={ saving }
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">EUR</span>
                            </div>
                        </div>
                        <small className="form-text text-muted">
                            De omvormer wordt uitgeschakeld wanneer de energieprijs onder deze prijs komt.
                            Hierbij wordt de energieprijs inclusief energiebelasting en inkoopkosten gebruikt.
                            <br/>
                            Vul niets in om deze functionaliteit uit te schakelen.
                        </small>
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="lowEnergyPriceThresholdZeroExport">Omvormer Zero Export prijs</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">≤</span>
                            </div>
                            <input
                                type="number"
                                className="form-control"
                                id="lowEnergyPriceThresholdZeroExport"
                                value={ settings.lowEnergyPriceThresholdZeroExport ?? "" }
                                onChange={ (event) => setSettings({ ...settings, lowEnergyPriceThresholdZeroExport: event.target.value }) }
                                disabled={ saving }
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">EUR</span>
                            </div>
                        </div>
                        <small className="form-text text-muted">
                            De omvormer ingesteld op Zero Export wanneer de energieprijs onder deze prijs komt.
                            Hierbij wordt de energieprijs inclusief energiebelasting en inkoopkosten gebruikt.
                            <br/>
                            Vul niets in om deze functionaliteit uit te schakelen.
                        </small>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(InstallationForm);
