import React, {
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";

function SendUserInstallationInviteModal({ installation, show, handleClose, onInviteSent }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [initialError, setInitialError] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState(null);

    const onShow = useMemo(() => {
        return () => {
            setName("");
            setEmail("");
            setRole("");
            setError(null);
            setInitialError(null);
            setLoading(false);
            const getRoles = async () => {
                try {
                    const response = await axios.get("/getUserInstallationInviteRoles");
                    const receivedRoles = response.data.roles;
                    setRoles(receivedRoles);
                    if(receivedRoles.length > 0) {
                        setRole(receivedRoles[0].value);
                    }
                } catch(error) {
                    console.error(error);
                    setInitialError("Er is een fout opgetreden. Probeer het later opnieuw.");
                }
            }
            getRoles();
        }
    }, []);

    const onSubmit = useMemo(() => {
        return async () => {
            setError(null);
            setLoading(true);
            try {
                await axios.post("/sendUserInstallationInvite", {
                    installationId: installation.id,
                    name,
                    email,
                    role
                });
                onInviteSent();
                handleClose();
            } catch(error) {
                setLoading(false);
                console.error(error);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            }
        }
    }, [installation, name, email, role, onInviteSent, handleClose]);

    return (
        <Modal show={ show } onShow={ onShow } onHide={ handleClose }>
            <Modal.Header>
                <Modal.Title>Uitnodiging versturen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { initialError ? (
                    <Alert variant="danger">{ initialError }</Alert>
                ) : !roles ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        { error && (
                            <Alert variant="danger">{ error }</Alert>
                        )}
                        <div className="form-group">
                            <label htmlFor="name">Naam</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={ name }
                                onChange={ (event) => { setName(event.target.value) }}
                                disabled={ loading }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">E-mailadres</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={ email }
                                onChange={ (event) => { setEmail(event.target.value) }}
                                disabled={ loading }
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="role">Rol</label>
                            <select
                                className="form-control"
                                id="role"
                                value={ role }
                                onChange={ (event) => { setRole(event.target.value) }}
                                disabled={ loading }
                            >
                                { roles && roles.map((role) => (
                                    <option
                                        key={ role.value }
                                        value={ role.value }
                                    >
                                        { role.name }
                                    </option>
                                ))}
                            </select>
                        </div>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={ onSubmit } disabled={ loading }>
                    { loading && (
                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                    )}
                    Verstuur
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(SendUserInstallationInviteModal);
