import React from "react";

import SettingsPaymentDirectDebit from "./components/SettingsPaymentDirectDebit";
import SettingsPaymentInvoiceData from "./components/SettingsPaymentInvoiceData";

function SettingsPayment() {
    return (
        <React.Fragment>
            <SettingsPaymentDirectDebit/>
            <SettingsPaymentInvoiceData/>
        </React.Fragment>
    )
}

export default React.memo(SettingsPayment);
