import React from "react";

import TagPill from "./TagPill";

function StatusPill({ value, ...props }) {
    if(!value) {
        return (
            <TagPill variant="secondary" { ...props }>
                Onbekend
            </TagPill>
        )
    }
    return (
        <TagPill color={ value.color } { ...props }>
            { value.name }
        </TagPill>
    )
}

export default React.memo(StatusPill);
