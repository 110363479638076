import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Link,
    useHistory
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import EnergyHandlingChart from "../installations/detail/components/EnergyHandlingChart";
import InstallationTypeIcon from "../../components/InstallationTypeIcon";
import ChartDateSwitcher from "../energy-prices/components/ChartDateSwitcher";

function EnergyHandlingModes({ match }) {
    const history = useHistory();
    const [date, setDate] = useState(null);
    const [installations, setInstallations] = useState(null);
    const [energyHandlingModes, setEnergyHandlingModes] = useState({});
    const [error, setError] = useState(null);
    const [installationError, setInstallationErrorInternal] = useState({});

    const loadingDate = useRef();

    useEffect(() => {
        if(!match.params.date) {
            setDate(moment());
        }
        setDate(moment(match.params.date));
    }, [match]);

    const getInstallations = useCallback(async () => {
        setInstallations(null);
        setEnergyHandlingModes({});
        setError(null);
        setInstallationErrorInternal({});
        try {
            const installationsResponse = await axios.get("/getInstallations");
            setInstallations(installationsResponse.data.installations);
            return installationsResponse.data.installations;
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            return null;
        }
    }, []);
    const setInstallationError = useCallback((installationId, errorMessage) => {
        setInstallationErrorInternal((prevState) => ({ ...prevState, [installationId]: errorMessage }));
    }, []);
    const getEnergyHandlingModes = useCallback(async (installationId, momentDate) => {
        setInstallationError(installationId, null);
        try {
            const energyHandlingModeResponse = await axios.post("/getEnergyHandlingForInstallation", {
                installationId,
                startDate: momentDate.clone().startOf("day").tz("Europe/Amsterdam").toISOString(),
                endDate: momentDate.clone().endOf("day").tz("Europe/Amsterdam").toISOString()
            });
            if(loadingDate.current !== momentDate.format("YYYY-MM-DD")) {
                return null;
            }
            setEnergyHandlingModes((prevState) => ({
                ...prevState,
                [installationId]: energyHandlingModeResponse.data.modesPerInverter
            }));
            return energyHandlingModeResponse.data.modesPerInverter;
        } catch(requestError) {
            console.error(requestError);
            setInstallationError(installationId, "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            return null;
        }
    }, [setInstallationError]);
    const loadDataForDate = useCallback(async (momentDate) => {
        const momentDateString = momentDate.format("YYYY-MM-DD");
        if(loadingDate.current === momentDateString) {
            // A loading operation is already in progress for this date. Prevent duplicate requests.
            return;
        }
        loadingDate.current = momentDateString;

        try {
            const installations = await getInstallations();
            if(installations) {
                for(const installation of installations) {
                    await getEnergyHandlingModes(installation.id, momentDate);

                    // If we change the date while loading, we should stop loading the data for the previous date.
                    if(loadingDate.current !== momentDateString) {
                        console.log("Stopped loading data for date", momentDateString);
                        return;
                    }
                }
            }
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw.");
            return;
        }

        // Release the lock on loadingDate.current.
        loadingDate.current = null;
    }, [getInstallations, getEnergyHandlingModes]);
    useEffect(() => {
        if(!date) {
            return;
        }
        console.log("Loading data for date", date.format("YYYY-MM-DD"));
        loadDataForDate(date);
    }, [date, loadDataForDate]);

    const onDateChange = useCallback((newDate) => {
        history.replace("/energy-handling-modes/" + newDate.format("YYYY-MM-DD"));
    }, [history]);

    if(!date) {
        return null;
    }

    return (
        <React.Fragment>
            <Helmet title="Energiesturing"/>
            <Title preTitle="Overzicht">
                Energiesturing
            </Title>

            <div className="card mb-3">
                <div className="card-body">
                    <ChartDateSwitcher
                        date={ date }
                        setDate={ onDateChange }
                    />
                </div>
            </div>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !installations ? (
                <Loading/>
            ) : (
                <Table hover>
                    <thead>
                    <tr className="tr-sticky">
                        <th/>
                        <th style={{ maxWidth: "200px" }}>Installatie</th>
                        <th>Sturing</th>
                    </tr>
                    </thead>
                    <tbody>
                    { installations.map((installation) => (
                        <tr key={ installation.id } className="table-clickable-row">
                            <td className="align-middle text-center">
                                <Link to={`/installation/${installation.id}`}>
                                    <InstallationTypeIcon
                                        type={ installation.type }
                                        className="text-muted"
                                    />
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={`/installation/${installation.id}`}>
                                    <b>{ installation.name }</b>
                                    <br/>
                                    <span className="text-muted">{ installation.address }, { installation.city }</span>
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={`/installation/${installation.id}`}>
                                    { installationError[installation.id] ? (
                                        <Alert variant="danger">{ installationError[installation.id] }</Alert>
                                    ) : energyHandlingModes[installation.id] ? (
                                        energyHandlingModes[installation.id].map((modesForInverter, index) => (
                                            <EnergyHandlingChart
                                                key={ index }
                                                hideLabels={ index !== energyHandlingModes[installation.id].length - 1 }
                                                modes={ modesForInverter.modes }
                                                unavailable={ modesForInverter.modes.length === 0 }
                                            />
                                        ))
                                    ) : (
                                        <Loading/>
                                    ) }
                                </Link>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    );
}

export default React.memo(EnergyHandlingModes);
