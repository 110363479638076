import React, {
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal
} from "react-bootstrap";

function ManualInverterStateSettingModal({ inverter, show, handleClose, refreshInverters }) {
    const [energyHandlingModes, setEnergyHandlingModes] = useState(null);
    const [state, setState] = useState(undefined);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const onShow = useMemo(() => {
        return async () => {
            if(!inverter) {
                return;
            }
            setError(null);
            setSaving(false);
            if(inverter.manualInverterStateSetting) {
                setState(inverter.manualInverterStateSetting.value);
            } else {
                setState("automatic");
            }

            try {
                const response = await axios.get("/getEnergyHandlingModes");
                setEnergyHandlingModes(response.data.modes);
            } catch(error) {
                console.error(error);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            }
        }
    }, [inverter]);
    const submit = useMemo(() => {
        return async () => {
            setSaving(true);
            try {
                await axios.post("/setManualInverterStateSetting", {
                    inverterId: inverter.id,
                    manualInverterStateSetting: state
                });
                setSaving(false);
                refreshInverters();
                handleClose();
            } catch(error) {
                console.error(error);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
                setSaving(false);
            }
        }
    }, [inverter, state, refreshInverters, handleClose]);
    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onShow }>
            <Modal.Header closeButton>
                <Modal.Title>Handmatige omvormer sturing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p>
                    Hier kun je handmatig de sturing van de omvormer veranderen, afwijkend van de automatische werking
                    indien gewenst.
                </p>
                <Form.Control
                    as="select"
                    value={ state }
                    onChange={ (event) => setState(event.target.value) }
                    disabled={ saving || !energyHandlingModes }
                >
                    <option value="automatic">Automatische werking</option>
                    { energyHandlingModes && energyHandlingModes.map((energyHandlingMode) => (
                        <option
                            key={ energyHandlingMode.value }
                            value={ energyHandlingMode.value }
                            style={{ backgroundColor: energyHandlingMode.color, color: "white" }}
                        >
                            { energyHandlingMode.name }
                        </option>
                    )) }
                </Form.Control>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ saving }>
                    Annuleer
                </Button>
                <Button variant="primary" onClick={ submit } disabled={ saving }>
                    Toepassen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(ManualInverterStateSettingModal);
