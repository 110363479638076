import React from "react";

function UserForm({ settings, setSettings, saving }) {
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Gegevens</h5>
                    <div className="form-group">
                        <label htmlFor="name">Naam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={ settings.name }
                            onChange={ (event) => setSettings({ ...settings, name: event.target.value }) }
                            disabled={ saving }
                        />
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={ settings.email }
                            onChange={ (event) => setSettings({ ...settings, email: event.target.value }) }
                            disabled={ saving }
                        />
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Wachtwoord</h5>
                    <div className="form-group">
                        <label htmlFor="password">Wachtwoord</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={ settings.password }
                            onChange={ (event) => setSettings({ ...settings, password: event.target.value }) }
                            disabled={ saving }
                        />
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="passwordConfirmation">Wachtwoord controle</label>
                        <input
                            type="password"
                            className="form-control"
                            id="passwordConfirmation"
                            value={ settings.passwordConfirmation }
                            onChange={ (event) => setSettings({ ...settings, passwordConfirmation: event.target.value }) }
                            disabled={ saving }
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(UserForm);
