function validateInstallationSettings({
    name,
    type,
    address,
    postalCode,
    city
}) {
    if(!name || name.length === 0) {
        return "Vul een naam in.";
    }
    if(!type || (type !== "private" && type !== "commercial")) {
        return "Ongeldig installatie type.";
    }
    if(!address || address.length === 0) {
        return "Vul een straat en huisnummer in.";
    }
    if(!postalCode || postalCode.length === 0) {
        return "Vul een postcode in.";
    }
    if(!city || city.length === 0) {
        return "Vul een plaats in.";
    }
    return null;
}

export default validateInstallationSettings;
