import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";

import InstallationInfoCard from "./components/overview-widgets/InstallationInfoCard";
import InstallationEnergyPricesChartCard from "./components/overview-widgets/InstallationEnergyPricesChartCard";
import InstallationCurrentEnergyPriceCard from "./components/overview-widgets/InstallationCurrentEnergyPriceCard";
import InstallationEnergyHandlingChart from "./components/overview-widgets/InstallationEnergyHandlingChart";
import ChartDateSwitcher from "../../energy-prices/components/ChartDateSwitcher";
import InstallationInverterModeHistoryChart from "./components/overview-widgets/InstallationInverterModeHistoryChart";
import InstallationProductionForecastChartCard from "./components/overview-widgets/InstallationProductionForecastChartCard";
import InstallationEnergyFlowAutoCard from "./components/overview-widgets/energy-flow/InstallationEnergyFlowAutoCard";
import InstallationBatteryHistoryChartCard from "./components/overview-widgets/InstallationBatteryHistoryChartCard";

function InstallationDetailOverview({ installation }) {
    const [date, setDate] = useState(moment().tz("Europe/Amsterdam", true));
    const [mobile, setMobile] = useState(false);
    const windowSize = useWindowSize();

    useEffect(() => {
        setMobile(windowSize.width < 992);
    }, [windowSize]);

    const chartDateRange = useMemo(() => {
        return {
            startDate: date.clone().startOf("day"),
            endDate: date.clone().endOf("day")
        };
    }, [date]);

    const onDateChange = useCallback((newDate) => {
        setDate(newDate);
    }, []);

    return (
        <React.Fragment>
            <div className="installation-detail-overview-container">
                <div className="col-main">
                    { mobile && (
                        <InstallationEnergyFlowAutoCard
                            installation={ installation }
                        />
                    )}
                    <div className="card mb-3">
                        <div className="card-body">
                            <ChartDateSwitcher
                                date={ date }
                                setDate={ onDateChange }
                            />
                        </div>
                    </div>
                    <InstallationEnergyPricesChartCard
                        startDate={ chartDateRange.startDate }
                        endDate={ chartDateRange.endDate }
                        installation={ installation }
                        height={ 400 }
                    />
                    { (!installation?.connectedDeviceTypes || installation?.connectedDeviceTypes?.includes("inverter")) && (
                        <InstallationEnergyHandlingChart
                            startDate={ chartDateRange.startDate }
                            endDate={ chartDateRange.endDate }
                            installation={ installation }
                        />
                    )}
                    { (!installation?.connectedDeviceTypes || installation?.connectedDeviceTypes?.includes("inverter")) && (
                        <InstallationInverterModeHistoryChart
                            startDate={ chartDateRange.startDate }
                            endDate={ chartDateRange.endDate }
                            installation={ installation }
                        />
                    )}
                    { (!installation?.connectedDeviceTypes || installation?.connectedDeviceTypes?.includes("battery")) && (
                        <InstallationBatteryHistoryChartCard
                            startDate={ chartDateRange.startDate }
                            endDate={ chartDateRange.endDate }
                            installation={ installation }
                        />
                    )}
                    <InstallationProductionForecastChartCard
                        startDate={ chartDateRange.startDate }
                        endDate={ chartDateRange.endDate }
                        installation={ installation }
                    />
                </div>
                <div className="col-sidebar">
                    { !mobile && (
                        <InstallationEnergyFlowAutoCard
                            installation={ installation }
                        />
                    )}
                    <InstallationCurrentEnergyPriceCard
                        installation={ installation }
                    />
                    <InstallationInfoCard installation={ installation }/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(InstallationDetailOverview);
