import React, {
    useState
} from "react";
import {
    Button,
    Form,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import ChartEnergyPrices from "../../../../../charts/ChartEnergyPrices";
import ChartCard from "../../../../../components/charts/ChartCard";
import EnergyPriceCostsExplanationModal from "../../modal/EnergyPriceCostsExplanationModal";

function ChartTitle({ includeAdditionalCosts, setIncludeAdditionalCosts, setShowEnergyPriceExplanation }) {
    return (
        <div className="d-flex flex-row w-100">
            <div className="d-flex flex-column flex-grow-1">
                <div>
                    Energieprijs
                </div>
                <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                    { includeAdditionalCosts ? (
                        "exclusief btw, inclusief inkoopkosten en energiebelasting"
                    ) : (
                        "exclusief btw, inkoopkosten en energiebelasting"
                    )}
                </div>
                <div style={{ fontSize: "0.85rem" }} className="mt-2">
                    <div className="d-flex flex-row align-items-center">
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Inclusief extra kosten"
                            checked={ includeAdditionalCosts }
                            onChange={ (event) => setIncludeAdditionalCosts(event.target.checked) }
                            className="d-flex flex-row align-items-start"
                            style={{ lineHeight: "20px" }}
                        />
                    </div>
                </div>
            </div>
            { setShowEnergyPriceExplanation && (
                <div>
                    <OverlayTrigger overlay={
                        <Tooltip id={`tooltip-chart-explanation`}>
                            Uitleg energieprijs
                        </Tooltip>
                    }>
                        <Button
                            variant="secondary"
                            size="sm"
                            onClick={ () => setShowEnergyPriceExplanation(true) }
                            className="ml-2"
                        >
                            <i className="fa-solid fa-question fa-fw"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            )}
        </div>
    )
}

function InstallationEnergyPricesChartCard({ startDate, endDate, installation, height }) {
    const [showEnergyPriceExplanation, setShowEnergyPriceExplanation] = useState(false);
    const [includeAdditionalCosts, setIncludeAdditionalCosts] = useState(true);
    return (
        <React.Fragment>
            <EnergyPriceCostsExplanationModal
                show={ showEnergyPriceExplanation }
                handleClose={ () => setShowEnergyPriceExplanation(false) }
            />
            <ChartCard
                title={
                    <ChartTitle
                        includeAdditionalCosts={ includeAdditionalCosts }
                        setIncludeAdditionalCosts={ setIncludeAdditionalCosts }
                    />
                }
                cardTitle={
                    <ChartTitle
                        includeAdditionalCosts={ includeAdditionalCosts }
                        setIncludeAdditionalCosts={ setIncludeAdditionalCosts }
                        setShowEnergyPriceExplanation={ setShowEnergyPriceExplanation }
                    />
                }
                height={ height }
            >
                <ChartEnergyPrices
                    startDate={ installation ? startDate : null }
                    endDate={ installation ? endDate : null }
                    installationId={ (installation && includeAdditionalCosts) ? installation.id : null }
                />
            </ChartCard>
        </React.Fragment>
    )
}

export default React.memo(InstallationEnergyPricesChartCard);
