import React from "react";

function TagPill({ children, className = "", color, variant = "primary", style = {}, ...props }) {
    return (
        <span
            className={ "badge badge-pill badge-" + variant + " " + (className ?? "") }
            style={{ backgroundColor: color, ...style }}
            { ...props }
        >
            { children }
        </span>
    );
}

export default React.memo(TagPill);
