import React from "react";
import {
    PageContainer,
    PageContent,
    PageFooter
} from "@zandor300/backoffice-framework";

import GridSenseVersion from "./GridSenseVersion";

function GridSensePageContainer({ children }) {

    return (
        <PageContainer>
            <PageContent>
                { children }
            </PageContent>
            <PageFooter>
                <GridSenseVersion/>
            </PageFooter>
        </PageContainer>
    )
}

export default React.memo(GridSensePageContainer);
