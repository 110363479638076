import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";
import GatewaySettingsForm from "./GatewaySettingsForm";

function InstallationGatewaySettingsModal({ show, handleClose, gateway, installation }) {
    const [settings, setSettings] = useState(null);
    const [editedValues, setEditedValues] = useState(null);
    const [error, setError] = useState(null);
    const [saveError, setSaveError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);

    const getSettings = useCallback(async () => {
        if(!gateway || !installation) {
            return;
        }
        setError(null);
        setSettings(null);
        setEditedValues(null);
        try {
            const response = await axios.post("/getGatewaySettings", {
                uuid: gateway.uuid,
                installationId: installation.id,
            });
            setSettings(response.data.settings);
            let values = {};
            response.data.settings.forEach((setting) => {
                values[setting.key] = setting.value;
            });
            setEditedValues(values);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [gateway, installation]);

    const saveSettings = useCallback(async () => {
        if(!gateway || !installation) {
            return;
        }
        setSaveError(null);
        setSaving(true);
        try {
            await axios.post("/setGatewaySettings", {
                uuid: gateway.uuid,
                installationId: installation.id,
                settings: JSON.stringify(editedValues),
            });
            setSuccess(true);
        } catch(requestError) {
            console.error(requestError);
            setSaveError("Er is iets fout gegaan. Probeer het later opnieuw.");
        } finally {
            setSaving(false);
        }
    }, [gateway, installation, editedValues]);

    useEffect(() => {
        if(!show) {
            return;
        }
        setSuccess(false);
        setSaveError(null);
        setSaving(false);
        getSettings();
    }, [getSettings, show]);

    const setValue = useCallback((key, value) => {
        setEditedValues((prevValues) => ({
            ...prevValues,
            [key]: value,
        }));
    }, []);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>
                    Gateway instellingen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { saveError && (
                    <Alert variant="danger">{ saveError }</Alert>
                )}
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : success ? (
                    <div className="text-center">
                        <h1 className="text-success"><i className="fa-solid fa-circle-check"/></h1>
                        <h3>Instellingen aangepast</h3>
                        <p className="mb-0">
                            De GridSense Gateway zal herstarten met de nieuwe instellingen.
                            Dit kan ongeveer een minuut duren.
                        </p>
                    </div>
                ) : !settings ? (
                    <Loading/>
                ) : (
                    <GatewaySettingsForm
                        settings={ settings }
                        values={ editedValues }
                        setValue={ setValue }
                        disabled={ saving }
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ saving }
                >
                    Annuleren
                </Button>
                { settings && (
                    <Button
                        variant="primary"
                        onClick={ saveSettings }
                        disabled={ saving }
                    >
                        { saving && (
                            <Spinner animation="border" size="sm" className="mr-2"/>
                        )}
                        Instellen
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(InstallationGatewaySettingsModal);
