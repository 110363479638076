import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import {
    useHistory
} from "react-router-dom";
import {
    Button,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import ChartCard from "../../components/charts/ChartCard";
import ChartDateSwitcher from "../energy-prices/components/ChartDateSwitcher";
import ChartImbalancePrices from "../../charts/ChartImbalancePrices";
import DateFormatter from "../../components/formatters/DateFormatter";
import NumberFormatter from "../../components/formatters/NumberFormatter";

function ImbalancePrices({ match }) {
    const [date, setDate] = useState(null);
    const [allPrices, setAllPrices] = useState(null);
    const [prices, setPrices] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if(!match.params.date) {
            setDate(moment());
        }
        setDate(moment(match.params.date));
    }, [match]);
    const dateRange = useMemo(() => {
        if(!date) {
            return null;
        }
        return {
            startDate: date.clone().startOf("day"),
            endDate: date.clone().endOf("day")
        }
    }, [date]);

    const onDateChange = useCallback((newDate) => {
        history.replace("/imbalance-prices/" + newDate.format("YYYY-MM-DD"));
        setPrices(null);
    }, [history]);

    const updatePrices = useCallback((newPrices) => {
        if(!newPrices) {
            return;
        }
        setAllPrices(newPrices.reverse());
    }, []);

    useEffect(() => {
        if(!allPrices) {
            setPrices(null);
            return;
        }
        const limit = 100;
        if(showAll || allPrices.length <= limit) {
            setPrices(allPrices);
            return;
        }
        setPrices(allPrices.slice(0, limit));
    }, [allPrices, showAll]);

    if(!dateRange) {
        return null;
    }
    return (
        <React.Fragment>
            <Helmet title="Onbalansprijs"/>
            <Title preTitle="Overzicht">
                Onbalansprijs
            </Title>
            <ChartCard
                cardTitle={
                    <ChartDateSwitcher
                        date={ date }
                        setDate={ onDateChange }
                    />
                }
                title={
                    <ChartDateSwitcher
                        date={ date }
                        setDate={ onDateChange }
                        tooltipPlacement="bottom"
                    />
                }
            >
                <ChartImbalancePrices
                    { ...dateRange }
                    updatePrices={ updatePrices }
                />
            </ChartCard>
            { prices && prices.length > 0 && (
                <Table hover>
                    <thead>
                    <tr className="tr-sticky-1 tr-vertical-border">
                        <th colSpan={2}>Tijdsindicatie</th>
                        <th colSpan={5}>Geactiveerd vermogen</th>
                        <th colSpan={3}>Prijsvorming</th>
                    </tr>
                    <tr className="tr-sticky-2">
                        <th className="text-center">Volgnummer</th>
                        <th className="text-center">Tijd</th>

                        <th className="text-center">Opregelen</th>
                        <th className="text-center">Afregelen</th>
                        <th className="text-center">Reservevermogen op</th>
                        <th className="text-center">Reservevermogen af</th>
                        <th className="text-center">Noodvermogen</th>

                        <th className="text-right">Invoeren (&euro; / MWh)</th>
                        <th className="text-right">Verschil</th>
                        <th className="text-right">Afnemen (&euro; / MWh)</th>
                    </tr>
                    </thead>
                    <tbody>
                    { prices.map((price, index) => {
                        const inPrice = price.maxPrice ?? price.minPrice ?? price.midPrice;
                        const outPrice = price.minPrice ?? price.maxPrice ?? price.midPrice;
                        return (
                            <tr key={ index }>
                                <td className="text-center"><NumberFormatter number={ price.sequenceNumber }/></td>
                                <td className="text-center"><DateFormatter date={ price.utcDate } format="HH:mm"/></td>

                                <td className="text-center"><NumberFormatter number={ price.upwardDispatch }/></td>
                                <td className="text-center"><NumberFormatter number={ price.downwardDispatch }/></td>
                                <td className="text-center"><NumberFormatter number={ price.reserveUpwardDispatch }/></td>
                                <td className="text-center"><NumberFormatter number={ price.reserveDownwardDispatch }/></td>
                                <td className="text-center">{ price.emergencyPower ? "Ja" : "Nee" }</td>

                                <td className={ "text-right " + (inPrice < 0 ? "text-danger" : "text-success") }>
                                    <NumberFormatter
                                        number={ inPrice }
                                        minimumFractionDigits={ 2 }
                                    />
                                </td>
                                <td className="text-right">
                                    { inPrice === outPrice ? (
                                        <i className="fa-solid fa-equals fa-fw"/>
                                    ) : (
                                        <i className="fa-solid fa-not-equal fa-fw text-danger"/>
                                    )}
                                </td>
                                <td className={ "text-right " + (outPrice < 0 ? "text-success" : "text-danger") }>
                                    <NumberFormatter
                                        number={ outPrice }
                                        minimumFractionDigits={ 2 }
                                    />
                                </td>
                            </tr>
                        );
                    }) }
                    </tbody>
                </Table>
            )}
            { allPrices && prices && allPrices.length > 100 && (
                <div className="d-flex justify-content-center">
                    <div>
                        <Button
                            className="mt-3"
                            onClick={ () => setShowAll(!showAll) }
                        >
                            { showAll ? "Toon minder" : "Toon alles" }
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(ImbalancePrices);
