import React, {
    useMemo
} from "react";

import NumberFormatter from "../../../../../../components/formatters/NumberFormatter";
import BatteryIcon from "../../../../../../components/BatteryIcon";

function ArrowIcon({ direction, className, ...props }) {
    const icon = useMemo(() => {
        switch (direction) {
            case "up": return "fa-arrow-up";
            case "down": return "fa-arrow-down";
            case "left": return "fa-arrow-left";
            case "right": return "fa-arrow-right";
            case "none-vertical": return "fa-pipe";
            case "none-horizontal": return "fa-pipe fa-rotate-90";
            default: return "";
        }
    }, [direction]);

    return (
        <i
            className={`fa-solid ${icon} fa-fw`}
            {...props}
        />
    )
}

function InstallationEnergyFlowLayout({ data }) {
    return (
        <div className="energy-flow-card d-flex flex-row">
            <div className="flex-grow-1"/>
            <div className="d-flex flex-column align-items-center pr-3 py-3">
                <div className="flex-grow-1 side-column-spacer">
                    <div style={{ height: "5px" }}/>
                </div>
                <div className="icon-container">
                    <i className="fa-solid fa-house fa-fw"/>
                </div>
                <div className="flex-grow-1 energy-flow-icon-text">
                    <NumberFormatter number={Math.round(Math.abs(data.housePower ?? 0))}/> W
                </div>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-center">
                    <div className="d-flex flex-column align-items-center pb-3 px-3">
                        <div className="icon-container">
                            <i className="fa-solid fa-solar-panel fa-fw"/>
                        </div>
                        <div className="energy-flow-icon-text">
                            <NumberFormatter number={Math.round(data.solarPower ?? 0)}/> W
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <ArrowIcon
                        direction={(data.solarPower ?? 0) > 0 ? "down" : "none-vertical"}
                        style={{ fontSize: "1.5rem" }}
                    />
                    <div className="d-flex flex-row align-items-center">
                        <ArrowIcon
                            direction={(data.housePower ?? 0) > 0 ? "left" : "none-horizontal"}
                            style={{ fontSize: "1.5rem" }}
                        />
                        <div className="icon-container-center">
                            <i className="fa-solid fa-bolt"/>
                        </div>
                        <ArrowIcon
                            direction={(data.gridPower ?? 0) === 0 ? "none-horizontal" : ((data.gridPower ?? 0) > 0 ? "left" : "right")}
                            style={{ fontSize: "1.5rem" }}
                        />
                    </div>
                    { data.batteryPower !== null && data.batterySoe !== null && (
                        <ArrowIcon
                            direction={(data.batteryPower ?? 0) === 0 ? "none-vertical" : ((data.batteryPower ?? 0) > 0 ? "down" : "up")}
                            style={{ fontSize: "1.5rem" }}
                        />
                    )}
                </div>

                { data.batteryPower !== null && data.batterySoe !== null && (
                    <div className="d-flex flex-row justify-content-center">
                        <div className="d-flex flex-column align-items-center pt-3 px-3">
                            <div className="icon-container">
                                <BatteryIcon soe={data.batterySoe} className="fa-fw"/>
                            </div>
                            <div className="energy-flow-icon-text">
                                <NumberFormatter number={Math.round(Math.abs(data.batteryPower ?? 0))}/> W
                            </div>
                            {data.batterySoe !== null ? (
                                <div className="energy-flow-icon-text second">
                                    {data.batteryPower === 0 ? "Standby" : (data.batteryPower > 0 ? "Laden" : "Ontladen")}
                                    {" "}(<NumberFormatter number={Math.round(data.batterySoe)}/>%)
                                </div>
                            ) : (" (0%)")}
                        </div>
                    </div>
                )}
            </div>
            <div className="d-flex flex-column align-items-center pl-3 py-3">
                <div className="flex-grow-1 side-column-spacer">
                    <div style={{ height: "20px" }}/>
                </div>
                <div className="icon-container">
                    <i className="fa-solid fa-utility-pole fa-fw"/>
                </div>
                <div className="flex-grow-1">
                    <div className="energy-flow-icon-text">
                        <NumberFormatter number={Math.round(Math.abs(data.gridPower ?? 0))}/> W
                    </div>
                    { data.gridPower ? (
                        <div className="energy-flow-icon-text second">
                            { data.gridPower > 0 ? "Import" : "Export" }
                        </div>
                    ) : (
                        <div className="energy-flow-icon-text second">
                            Geen
                        </div>
                    )}
                </div>
            </div>
            <div className="flex-grow-1"/>
        </div>
    );
}

export default React.memo(InstallationEnergyFlowLayout);
