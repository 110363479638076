import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

function ResendUserInstallationInviteModal({ show, handleClose, onInviteResent, installation, inviteToResend }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!show) {
            return;
        }
        setLoading(false);
        setError(null);
    }, [show]);
    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            await axios.post("/resendUserInstallationInvite", {
                installationId: installation.id,
                userInstallationInviteId: inviteToResend.id });
            handleClose();
            onInviteResent();
        } catch(requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        }
    }, [installation, inviteToResend, handleClose, onInviteResent]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Uitnodiging opnieuw verzenden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <div className="alert alert-danger">{ error }</div>
                )}
                <p className="mb-0">
                    Weet je zeker dat je de uitnodiging opnieuw wilt verzenden naar <strong>{ inviteToResend?.email }</strong>?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ loading }
                >
                    Annuleren
                </Button>
                <Button
                    variant="primary"
                    onClick={ onSubmit }
                    disabled={ loading }
                >
                    { loading && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Verzenden
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(ResendUserInstallationInviteModal);
