import React from "react";
import {
    friendlyFormatIBAN
} from "ibantools";

function IBANFormatter({ iban }) {
    return friendlyFormatIBAN(iban);
}

export default React.memo(IBANFormatter);
