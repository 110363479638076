import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Spinner
} from "react-bootstrap";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageColumnFooter,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

import Helmet from "../../components/Helmet";
import Copyright from "./Copyright";
import Logo from "../../components/Logo";
import Loading from "../../components/Loading";

function ResetPassword() {
    const match = useRouteMatch();
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [valid, setValid] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const token = match.params.token;
        if(!token) {
            setValid(false);
            setError("Ongeldige wachtwoord reset token.");
            return;
        }
        axios.post("/checkResetPasswordToken", { token })
            .then((response) => {
                if(response.data.valid) {
                    setValid(true);
                } else {
                    setValid(false);
                    setError("Ongeldige wachtwoord reset token.");
                }
            })
            .catch((error) => {
                console.error(error);
                setValid(false);
                setError("Ongeldige wachtwoord reset token.");
            });
    }, [match]);

    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            setLoading(true);
            axios.post("/resetPassword", {
                token: match.params.token,
                password,
                passwordConfirm
            })
                .then((response) => {
                    if(response.data.valid) {
                        setSuccess("Het wachtwoord is aangepast. Log opnieuw in met het nieuwe wachtwoord.");
                    } else {
                        setError(authenticatedUserContext.getErrorMessage(response.data.error));
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setError(authenticatedUserContext.getErrorMessage());
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [match, authenticatedUserContext, password, passwordConfirm]);

    return (
        <AuthenticationPage>
            <Helmet/>
            <AuthenticationPageColumn>
                <Logo className="login-logo"/>
                <h3 className="text-center text-muted mt-0">
                    Wachtwoord instellen
                </h3>
                <div className="text-center mb-4">
                    <Link to="/login" style={{ fontSize: "0.9rem" }}>
                        <i className="fa-solid fa-chevron-left mr-2"/>
                        Terug naar inloggen
                    </Link>
                </div>
                { error && (
                    <Alert variant="danger">
                        { error }
                    </Alert>
                )}
                { valid === null ? (
                    <div>
                        <Loading/>
                    </div>
                ) : !valid ? null : success ? (
                    <Alert variant="success">
                        { success }
                    </Alert>
                ) : (
                    <form onSubmit={ onSubmit }>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Wachtwoord"
                                disabled={ loading }
                                value={ password }
                                onChange={(event) => setPassword(event.target.value) }
                                autoComplete="new-password"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Wachtwoord verificatie"
                                disabled={ loading }
                                value={ passwordConfirm }
                                onChange={(event) => setPasswordConfirm(event.target.value) }
                                autoComplete="new-password"
                            />
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={ loading }>
                                { loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Verstuur
                            </button>
                        </div>
                    </form>
                )}

                <AuthenticationPageColumnFooter>
                    <a href="https://smithsolar.nl">
                        Smith Solar website
                    </a>
                </AuthenticationPageColumnFooter>
            </AuthenticationPageColumn>

            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    )
}

export default React.memo(ResetPassword);
